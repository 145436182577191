import { TextField, Autocomplete } from '@mui/material';
import React from 'react';
import { connect } from 'react-redux';
import * as actions from './actions';

function CustomerSelect(props) {
	console.log(props.customers);
	const { style, width, ...otherProps } = props;

	return (
		<div style={{ width: width || '250px', ...style }}>
			{Array.isArray(props.customers) ? (
				<Autocomplete
					options={props.customers}
					getOptionLabel={(c) => c.name}
					onChange={(e, v) => {
						if (v) {
							props.onSelect(v._id, v.name);
						} else {
							props.onSelect(null, null);
						}
					}}
					renderInput={(params) => (
						<TextField {...params} label="Customer" fullWidth />
					)}
					renderOption={(props, option) => (
						<li {...props} key={option._id}>
							{option.name}
						</li>
					)}
				/>
			) : null}
		</div>
	);
}

export default connect((state) => state, actions)(CustomerSelect);
