import React, { useState } from 'react';
import {
	TextField,
	IconButton,
	Typography,
	FormControlLabel,
	Switch,
	CircularProgress,
	Snackbar,
	CardContent,
	Grid,
	Box,
	Tooltip,
	Tabs,
	Tab,
} from '@mui/material';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import CustomerSelect from './CustomerSelect';
import { getData } from './network';
import SendIcon from '@mui/icons-material/Send';
import HelpIcon from '@mui/icons-material/Help';
import { styled } from '@mui/styles';

const StyledDiv = styled('div')(({ theme }) => ({
	marginBottom: theme.spacing(3),
}));

function TabPanel(props) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`tabpanel-${index}`}
			aria-labelledby={`tab-${index}`}
			{...other}
		>
			{value === index && <Box sx={{ p: 3 }}>{children}</Box>}
		</div>
	);
}

function PushCallCRM() {
	const [activeTab, setActiveTab] = useState(0);
	const [selectedCustomerId, setSelectedCustomerId] = useState('');
	const [startDate, setStartDate] = useState(new Date());
	const [endDate, setEndDate] = useState(new Date());
	const [callIdsStr, setCallIdsStr] = useState('');
	const [isLoading, setIsLoading] = useState(false);
	const [forcePush, setForcePush] = useState(false);
	const [linkCall, setLinkCall] = useState(true);
	const [snackPack, setSnackPack] = useState([]);
	const [responseMessage, setResponseMessage] = useState('');

	const handleStartDateChange = (newValue) => {
		setStartDate(newValue);
	};

	const handleEndDateChange = (newValue) => {
		setEndDate(newValue);
	};

	const handleSnackBarClose = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}
		setSnackPack((prev) => prev.slice(1));
	};

	const handleSubmit = (byIds) => {
		return async () => {
			setIsLoading(true);
			try {
				var callIds = [];
				if (byIds && callIdsStr) {
					for (const callIdsLine of callIdsStr.split('\n').map((c) => c.trim())) {
						if (callIdsLine) {
							for (const callId of callIdsLine.split(',').map((c) => c.trim())) callIds.push(callId);
						}
					}
				}
				const endpoint = '/support_tools/push-calls-to-CRM';
				const params = {
					customerId: selectedCustomerId,
					startDate: startDate.toISOString(),
					endDate: endDate.toISOString(),
					callIds,
					forcePush,
					linkCall,
				};
				if (byIds || (callIds && callIds.length)) {
					params.startDate = ''
					params.endDate = ''
				}
				const response = await getData(endpoint, params);
				if (!response.ok) {
					throw new Error('Failed to push call data to CRM');
				}
				const resultText = await response.text();
				setResponseMessage(resultText);
				setSnackPack((prev) => [
					...prev,
					{ message: resultText, key: new Date().getTime() },
				]);
			} catch (error) {
				console.error('Error occurred:', error);
				setResponseMessage(error.toString());
				setSnackPack((prev) => [
					...prev,
					{ message: error.toString(), key: new Date().getTime() },
				]);
			} finally {
				setIsLoading(false);
			}
		}
	};

	return (
		<LocalizationProvider dateAdapter={AdapterDateFns}>
			<Grid
				container
				spacing={2}
				direction="column"
				style={{ margin: '20px auto', width: '80%' }}
			>
				<Typography variant="h4" gutterBottom>
					Link and Push calls to CRM
				</Typography>

				{/* Tabs */}
				<Tabs
					value={activeTab}
					onChange={(e, newValue) => setActiveTab(newValue)}
					variant="scrollable"
					scrollButtons="auto"
					allowScrollButtonsMobile
					centered={false}
					style={{ width: '100%' }}
				>
					<Tab label="By Timerange" />
					<Tab label="By Ids" />
				</Tabs>

				<TabPanel value={activeTab} index={0}>
					<StyledDiv>
						<Typography variant="h6">By Timerange</Typography>
						<Grid
							container
							alignItems="center"
							justifyContent="center"
							spacing={2}
						>
							<Grid item xs={12} style={{ boxShadow: 'none' }}>
								<CardContent>
									<Grid
										container
										spacing={2}
										alignItems="center"
										justifyContent="space-around"
									>
										<Grid item xs={12} md={3} sx={{ flexGrow: 1 }}>
											<CustomerSelect
												onSelect={setSelectedCustomerId}
												fullWidth
											/>
										</Grid>
										<Grid item xs={12} md={3} sx={{ flexGrow: 1 }}>
											<DateTimePicker
												label="Start Date"
												value={startDate}
												onChange={handleStartDateChange}
												renderInput={(props) => (
													<TextField {...props} />
												)}
											/>
										</Grid>
										<Grid item xs={12} md={3} sx={{ flexGrow: 1 }}>
											<DateTimePicker
												label="End Date"
												value={endDate}
												onChange={handleEndDateChange}
												renderInput={(props) => (
													<TextField {...props} />
												)}
											/>
										</Grid>
									</Grid>
									<Box
										sx={{
											boxShadow: 3,
											borderRadius: 1,
											marginTop: 4,
											padding: 1,
											marginX: 'auto',
											height: 'fit-content',
											maxWidth: 'fit-content',
										}}
									>
										<Grid
											item
											container
											spacing={2}
											justifyContent="flex-start"
											alignItems="center"
										>
											<Grid item>
												<FormControlLabel
													control={
														<Switch
															checked={forcePush}
															onChange={(e) =>
																setForcePush(
																	e.target.checked
																)
															}
														/>
													}
													label="Force Push"
												/>
											</Grid>
											<Grid item>
												<FormControlLabel
													control={
														<Switch
															checked={linkCall}
															onChange={(e) =>
																setLinkCall(
																	e.target.checked
																)
															}
														/>
													}
													label="Link Call"
												/>
											</Grid>
											<Grid item>
												{isLoading ? (
													<CircularProgress />
												) : (
													<IconButton
														color="primary"
														onClick={handleSubmit(false)}
													>
														<SendIcon />
													</IconButton>
												)}
											</Grid>
										</Grid>
									</Box>

									<Grid item xs={12} mt={3}>
										<Typography variant="h6" align="center">
											{responseMessage}
										</Typography>
									</Grid>
								</CardContent>
							</Grid>
						</Grid >
						<Snackbar
							open={snackPack.length > 0}
							autoHideDuration={7000}
							onClose={handleSnackBarClose}
							message={snackPack[0]?.message}
							key={snackPack[0]?.key}
							anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
						/>
					</StyledDiv>
				</TabPanel>
				<TabPanel value={activeTab} index={1}>
					<StyledDiv>
						<Typography variant="h6">By Ids</Typography>
						<Grid
							container
							alignItems="center"
							justifyContent="center"
							spacing={2}
						>
							<Grid item xs={12} style={{ boxShadow: 'none' }}>
								<CardContent>
									<Grid
										container
										spacing={2}
										alignItems="center"
										justifyContent="space-around"
									>
										<Grid item xs={12} md={3} sx={{ flexGrow: 1 }}>
											<CustomerSelect
												onSelect={setSelectedCustomerId}
												fullWidth
											/>
										</Grid>
										<Grid item xs={12} md={3} sx={{ flexGrow: 1 }}>
											<TextField
												style={{ width: '350px' }}
												value={callIdsStr}
												onChange={(e) => setCallIdsStr(e.target.value)}
												label="Call ids"
												multiline
											/>
										</Grid>
										<Tooltip title="Add either comma separated or line separated list of call ids" placement="top-start">
											<HelpIcon />
										</Tooltip>
									</Grid>
									<Box
										sx={{
											boxShadow: 3,
											borderRadius: 1,
											marginTop: 4,
											padding: 1,
											marginX: 'auto',
											height: 'fit-content',
											maxWidth: 'fit-content',
										}}
									>
										<Grid
											item
											container
											spacing={2}
											justifyContent="flex-start"
											alignItems="center"
										>
											<Grid item>
												<FormControlLabel
													control={
														<Switch
															checked={forcePush}
															onChange={(e) =>
																setForcePush(
																	e.target.checked
																)
															}
														/>
													}
													label="Force Push"
												/>
											</Grid>
											<Grid item>
												<FormControlLabel
													control={
														<Switch
															checked={linkCall}
															onChange={(e) =>
																setLinkCall(
																	e.target.checked
																)
															}
														/>
													}
													label="Link Call"
												/>
											</Grid>
											<Grid item>
												{isLoading ? (
													<CircularProgress />
												) : (
													<IconButton
														color="primary"
														onClick={handleSubmit(true)}
													>
														<SendIcon />
													</IconButton>
												)}
											</Grid>
										</Grid>
									</Box>

									<Grid item xs={12} mt={3}>
										<Typography variant="h6" align="center">
											{responseMessage}
										</Typography>
									</Grid>
								</CardContent>
							</Grid>
						</Grid >
						<Snackbar
							open={snackPack.length > 0}
							autoHideDuration={7000}
							onClose={handleSnackBarClose}
							message={snackPack[0]?.message}
							key={snackPack[0]?.key}
							anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
						/>
					</StyledDiv>
				</TabPanel>
			</Grid>
		</LocalizationProvider >
	);
}

export default PushCallCRM;
