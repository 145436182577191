//-------------------
export const SECONDARY_EMAIL_UPDATE_MODE = {
  SINGLE_USER: 'SINGLE_USER',
  BULK_USERS: 'BULK_USER',
};
export const SECONDARY_EMAIL_UPDATE_OPTIONS = [
  { label: 'Single User', value: SECONDARY_EMAIL_UPDATE_MODE.SINGLE_USER },
  { label: 'Bulk Users', value: SECONDARY_EMAIL_UPDATE_MODE.BULK_USERS },
];

export const customStyles = {
  control: (base, state) => ({
    ...base,
    marginTop: '0.25rem',
    background: '#F5F5F7',
    // Overwrittes the different states of border
    borderColor: state.isFocused ? 'black' : 'rgba(0,0,0,0.6)',
    // Removes weird border around container
    boxShadow: state.isFocused ? null : null,
    '&:hover': {
      borderColor: 'black',
    },
  }),
};

export const addSecondaryEmailToUser = (user, email) => {
  const updatedSecondaryEmails = new Set(user?.secondary_email_id || []);
  updatedSecondaryEmails.add(email);
  return { ...user, secondary_email_id: updatedSecondaryEmails };
};

export const removeSecondaryEmailFromUser = (user, email) => {
  const updateSecondaryEmails = new Set(user?.secondary_email_id || []);
  updateSecondaryEmails.delete(email);
  return { ...user, secondary_email_id: updateSecondaryEmails };
};
