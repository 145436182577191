import SecondaryEmailUpdatePage  from "components/customerSupport/secondaryEmail/SecondaryEmailUpdatePage";
import React, { useState } from 'react';
import {
	TextField,
	Button,
	CircularProgress,
	Snackbar,
	Alert,
	Input,
	Typography,
	Grid,
	Tabs,
	Tab,
	Box,
	FormControlLabel,
	Switch,
	Paper,
} from '@mui/material';
import ObjectTreeView from './ObjectTreeView';
import { getData, postJson } from './network';
import { validateEmail } from 'utils/helpers';
import Papa from 'papaparse';
import { styled } from '@mui/styles';
import { CLARI_CUSTOMER_ID } from 'utils/constants';
import { CheckCircle, Cancel, Help } from '@mui/icons-material';
import { green, red, orange } from '@mui/material/colors';

const StyledDiv = styled('div')(({ theme }) => ({
	marginBottom: theme.spacing(3),
}));

const StyledInput = styled(Input)(({ theme }) => ({
	width: '100%',
}));

const StyledButton = styled(Button)(({ theme }) => ({
	width: 'auto',
	marginLeft: theme.spacing(2),
}));

function TabPanel(props) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`tabpanel-${index}`}
			aria-labelledby={`tab-${index}`}
			{...other}
		>
			{value === index && <Box sx={{ p: 3 }}>{children}</Box>}
		</div>
	);
}

function UserEmail() {
	const [activeTab, setActiveTab] = useState(0);
	const [userEmailForLookup, setUserEmailForLookup] = useState('');
	const [changeEmail, setChangeEmail] = useState('');
	const [newEmail, setNewEmail] = useState('');
	const [userData, setUserData] = useState(null);
	const [isLoading, setIsLoading] = useState(false);
	const [error, setError] = useState('');
	const [success, setSuccess] = useState('');
	const [file, setFile] = useState(null);
	const [fileName, setFileName] = useState('');
	const [customerId, setCustomerId] = useState('');
	const [newDomain, setNewDomain] = useState('');
	const [bulkUpdateResults, setBulkUpdateResults] = useState(null);
	const [domainUpdateFile, setDomainUpdateFile] = useState(null);
	const [domainUpdateFileName, setDomainUpdateFileName] = useState('');
	const [isSuperAdmin, setIsSuperAdmin] = useState(false);
	const [toggleSwitch, setToggleSwitch] = useState(false);
	const [recordingStatusEmail, setRecordingStatusEmail] = useState('');
	const [recordingStatus, setRecordingStatus] = useState(null);

	<Tabs
		value={activeTab}
		onChange={(e, newValue) => setActiveTab(newValue)}
		variant="scrollable"
		scrollButtons="auto"
		allowScrollButtonsMobile
	>
		<Tab label="Fetch User" />
		<Tab label="Change Email" />
		<Tab label="Bulk Update Emails" />
		<Tab label="Change Domain" />
		<Tab label="Bulk update domain" />
		<Tab label="Local Recording Status" />
	</Tabs>;

	const handleToggleSuperAdmin = async () => {
		const payload = {
			email: userEmailForLookup.toLowerCase(),
			isSuperAdmin: !isSuperAdmin,
		};

		try {
			const response = await postJson(
				'/support_tools/update-super-admin',
				payload
			);

			if (!response.ok) {
				const errorData = await response.json();
				throw new Error(
					errorData.error || 'Failed to update super admin status.'
				);
			}

			const updatedUserData = {
				...userData,
				is_super_admin: !isSuperAdmin,
			};
			setUserData(updatedUserData);
			setIsSuperAdmin(!isSuperAdmin);
			setToggleSwitch(!isSuperAdmin);
			setSuccess('Super admin status updated successfully.');
		} catch (error) {
			console.error(
				'Error during super admin status update:',
				error.message
			);
			setError(error.message);
			setToggleSwitch(isSuperAdmin);
		}
	};

	const handleDomainUpdateFileChange = (event) => {
		const file = event.target.files[0];
		if (file) {
			setDomainUpdateFile(file);
			setDomainUpdateFileName(file.name);
		} else {
			setDomainUpdateFileName('');
		}
	};
	const handleBulkUpdateDomains = async () => {
		if (!domainUpdateFile) {
			setError('Please select a domain update file.');
			return;
		}
		setIsLoading(true);
		Papa.parse(domainUpdateFile, {
			complete: async (results) => {
				const domainPairs = results.data
					.map((row) => ({
						userId: row[0],
						newDomain: row[1],
					}))
					.filter((pair) => pair.userId && pair.newDomain);

				if (domainPairs.length === 0) {
					setError('Invalid CSV format or empty domain update file.');
					setIsLoading(false);
					return;
				}

				try {
					const response = await postJson(
						'/support_tools/bulk-update-domain',
						{ updates: domainPairs }
					);

					if (response.ok) {
						const data = await response.json();
						setBulkUpdateResults(data.bulkUpdateResult || {});
						setSuccess(
							`Bulk domain update successful. Updated records: ${data.bulkUpdateResult.modifiedCount}`
						);
					} else {
						const errorData = await response.json();
						setError(errorData.error || 'Bulk update failed.');
					}
				} catch (error) {
					console.error('Error during bulk domain update:', error);
					setError(
						'An error occurred while processing your request.'
					);
				} finally {
					setIsLoading(false);
				}
			},
			header: false,
		});
	};

	const handleFileChange = (event) => {
		const file = event.target.files[0];
		if (file) {
			setFile(file);
			setFileName(file.name);
		} else {
			setFileName('');
		}
	};

	const handleApiResponse = async (response, successMessage) => {
		setIsLoading(false);
		if (response.ok) {
			const data = await response.json();
			setSuccess(successMessage);
			return data;
		} else {
			const data = await response.json();
			setError(data.error || 'An unexpected error occurred.');
		}
	};

	const handleFetchUser = async () => {
		if (!validateEmail(userEmailForLookup)) {
			setError('Please enter a valid email address.');
			setUserData(null);
			return;
		}
		setIsLoading(true);
		try {
			const response = await getData(`/support_tools/user-by-email`, {
				email: userEmailForLookup,
			});
			const userDoc = await response.json();

			if (response.ok) {
				setUserData(userDoc);
				if (userDoc.strings_customer?.$id === CLARI_CUSTOMER_ID) {
					setIsSuperAdmin(userDoc.is_super_admin);
				}
				setSuccess('User data fetched successfully.');
			} else {
				setError(userDoc.error || 'An unexpected error occurred.');
				setUserData(null);
			}
		} catch (error) {
			console.error('Error fetching user data:', error);
			setError(
				error.response?.data?.error ||
					'An error occurred while fetching user data.'
			);
			setUserData(null);
		} finally {
			setIsLoading(false);
		}
	};

	const handleChangeEmail = async () => {
		if (!validateEmail(newEmail) || !validateEmail(changeEmail)) {
			setError('Please enter valid email addresses.');
			return;
		}
		setIsLoading(true);
		const response = await postJson('/support_tools/update-user-email', {
			oldEmail: changeEmail,
			newEmail,
		});
		await handleApiResponse(response, 'Email updated successfully.');
	};

	const handleBulkUpdateEmails = async () => {
		if (!file) {
			setError('Please select a file.');
			return;
		}
		setIsLoading(true);
		Papa.parse(file, {
			complete: async (results) => {
				const emailPairs = results.data
					.map((row) => ({ oldEmail: row[0], newEmail: row[1] }))
					.filter((pair) => pair.oldEmail && pair.newEmail);
				const totalEmailPairs = emailPairs.length;
				if (totalEmailPairs === 0) {
					setError('Invalid CSV format or empty file.');
					setIsLoading(false);
					return;
				}
				const response = await postJson(
					'/support_tools/bulk-update-emails',
					{ emailPairs }
				);
				if (response.ok) {
					const data = await response.json();
					setBulkUpdateResults(data.bulkUpdateResult);
					if (data.bulkUpdateResult.matchedCount === 0) {
						setError('No emails matched.');
					} else if (
						data.bulkUpdateResult.matchedCount < totalEmailPairs
					) {
						setError(
							`Partial update successful. Matched: ${data.bulkUpdateResult.matchedCount}, Modified: ${data.bulkUpdateResult.modifiedCount}. Some emails may not have matched.`
						);
					} else {
						setSuccess(
							`Bulk update processed. Matched: ${data.bulkUpdateResult.matchedCount}, Modified: ${data.bulkUpdateResult.modifiedCount}.`
						);
					}
				} else {
					const errorData = await response.json();
					setError(errorData.error || 'Bulk update failed.');
				}
				setIsLoading(false);
			},
			header: false,
		});
	};

	const handleDomainChange = async () => {
		if (!validateEmail(`test@${newDomain}`) || !customerId) {
			setError('Please enter a valid domain and customer ID.');
			return;
		}
		setIsLoading(true);
		const payload = {
			customerID: customerId,
			newDomain,
		};

		try {
			const response = await postJson(
				'/support_tools/update-email-domain-for-customer',
				payload
			);

			if (response.ok) {
				const data = await response.json();
				if (data && data.bulkUpdateResult) {
					setBulkUpdateResults(data.bulkUpdateResult);
					setSuccess(
						`Domain update processed successfully. Matched: ${data.bulkUpdateResult.matchedCount}, Modified: ${data.bulkUpdateResult.modifiedCount}`
					);
				} else {
					setError(
						'No domains were updated. Please check the provided data.'
					);
				}
			} else {
				const errorData = await response.json();
				setError(errorData.error || 'Bulk update failed.');
			}
		} catch (error) {
			console.error('Error in handleDomainChange:', error);
			setError('An error occurred while processing your request.');
		} finally {
			setIsLoading(false);
		}
	};

	// Component for displaying operation results or errors
	const OperationFeedback = ({ severity = 'error' }) => (
		<>
			{error && (
				<Snackbar
					open
					autoHideDuration={6000}
					onClose={() => setError('')}
				>
					<Alert
						onClose={() => setError('')}
						severity={severity}
						sx={{ width: '100%' }}
					>
						{error}
					</Alert>
				</Snackbar>
			)}
			{success && (
				<Snackbar
					open
					autoHideDuration={6000}
					onClose={() => setSuccess('')}
				>
					<Alert
						onClose={() => setSuccess('')}
						severity="success"
						sx={{ width: '100%' }}
					>
						{success}
					</Alert>
				</Snackbar>
			)}
		</>
	);

	const RecordingStatusDisplay = ({ status }) => {
		let icon, color, text;

		if (status === 'Local recording status is true') {
			icon = <CheckCircle style={{ fontSize: 60, color: green[500] }} />;
			color = green[100];
			text = 'Local Recording is Enabled';
		} else if (status === 'Local recording status is false') {
			icon = <Cancel style={{ fontSize: 60, color: red[500] }} />;
			color = red[100];
			text = 'Local Recording is Disabled';
		} else {
			icon = <Help style={{ fontSize: 60, color: orange[500] }} />;
			color = orange[100];
			text = 'Status Unknown';
		}

		return (
			<Paper
				elevation={3}
				style={{
					padding: '20px',
					marginTop: '20px',
					backgroundColor: color,
					textAlign: 'center',
				}}
			>
				{icon}
				<Typography variant="h6" style={{ marginTop: '10px' }}>
					{text}
				</Typography>
				<Typography variant="body2" style={{ marginTop: '5px' }}>
					{status}
				</Typography>
			</Paper>
		);
	};
	const handleFetchRecordingStatus = async () => {
		if (!validateEmail(recordingStatusEmail)) {
			setError('Please enter a valid email address.');
			setRecordingStatus(null);
			return;
		}
		setIsLoading(true);
		try {
			const response = await getData(
				`/support_tools/user_recording_status`,
				{
					email: recordingStatusEmail,
				}
			);
			const data = await response.json();

			if (response.ok) {
				setRecordingStatus(data.message);
				setSuccess('Recording status fetched successfully.');
			} else {
				setError(data.error || 'An unexpected error occurred.');
				setRecordingStatus(null);
			}
		} catch (error) {
			console.error('Error fetching recording status:', error);
			setError(
				error.response?.data?.error ||
					'An error occurred while fetching recording status.'
			);
			setRecordingStatus(null);
		} finally {
			setIsLoading(false);
		}
	};

	return (
		<Grid
			container
			spacing={2}
			direction="column"
			style={{ margin: '20px auto', width: '80%'}}
		>
			<Typography variant="h4" gutterBottom>
				User Email Management
			</Typography>

			{/* Tabs */}
			<Tabs
				value={activeTab}
				onChange={(e, newValue) => setActiveTab(newValue)}
				variant="scrollable"
				scrollButtons="auto"
				allowScrollButtonsMobile
				centered={false}
				style={{ width: '100%' }}
			>
				<Tab label="Fetch User" />
				<Tab label="Change Email" />
				<Tab label="Bulk Update Emails" />
				<Tab label="Change Domain" />
				<Tab label="Bulk update domain" />
				<Tab label="Local Recording Status" />
				<Tab label="Secondary Email" />
			</Tabs>

			<TabPanel value={activeTab} index={0}>
				{/* Fetch User Content */}
				<StyledDiv>
					<Typography variant="h6">Fetch User</Typography>
					<Grid container alignItems="center" spacing={2}>
						<Grid item xs={8}>
							<TextField
								fullWidth
								label="Enter user email"
								variant="outlined"
								value={userEmailForLookup}
								onChange={(e) =>
									setUserEmailForLookup(e.target.value.trim())
								}
							/>
						</Grid>
						<Grid item xs={4}>
							<Button
								variant="contained"
								onClick={handleFetchUser}
								disabled={isLoading || !userEmailForLookup}
								fullWidth
							>
								Fetch User
							</Button>
						</Grid>
					</Grid>
					{userData &&
						userData.strings_customer.$id ===
							'620fd3e42f2c5316b2aaf887' && (
							<FormControlLabel
								control={
									<Switch
										checked={userData.is_super_admin}
										onChange={handleToggleSuperAdmin}
									/>
								}
								label="Super Admin"
							/>
						)}
					{userData && (
						<ObjectTreeView
							key={Date.now()}
							name="User Data"
							obj={userData}
						/>
					)}
				</StyledDiv>
			</TabPanel>

			<TabPanel value={activeTab} index={1}>
				{/* Change Email Content */}
				<StyledDiv>
					<Typography variant="h6">Change Email</Typography>
					<Grid container alignItems="center" spacing={2}>
						<Grid item xs={5}>
							<TextField
								fullWidth
								label="Old Email"
								variant="outlined"
								value={changeEmail}
								onChange={(e) =>
									setChangeEmail(e.target.value.trim())
								}
							/>
						</Grid>
						<Grid item xs={5}>
							<TextField
								fullWidth
								label="New Email"
								variant="outlined"
								value={newEmail}
								onChange={(e) =>
									setNewEmail(e.target.value.trim())
								}
							/>
						</Grid>
						<Grid item xs={2}>
							<Button
								variant="contained"
								color="secondary"
								onClick={handleChangeEmail}
								disabled={
									isLoading || !newEmail || !changeEmail
								}
								fullWidth
							>
								Update
							</Button>
						</Grid>
					</Grid>
				</StyledDiv>
			</TabPanel>
			<TabPanel value={activeTab} index={2}>
				{/* Bulk Update Emails Content */}
				<StyledDiv>
					<Typography variant="h6">Bulk Update Emails</Typography>
					<Grid container alignItems="center" spacing={2}>
						<Grid item xs={8}>
							<StyledInput
								type="file"
								onChange={handleFileChange}
								disableUnderline
							/>
						</Grid>
						<Grid item xs={4}>
							<StyledButton
								variant="contained"
								onClick={handleBulkUpdateEmails}
								disabled={isLoading}
							>
								Bulk Update
							</StyledButton>
						</Grid>
					</Grid>
				</StyledDiv>
			</TabPanel>
			<TabPanel value={activeTab} index={3}>
				{/* Change Domain Content */}
				<StyledDiv>
					<Typography variant="h6">Change Domain</Typography>
					<Grid container alignItems="center" spacing={2}>
						<Grid item xs={5}>
							<TextField
								fullWidth
								label="Customer ID"
								variant="outlined"
								value={customerId}
								onChange={(e) =>
									setCustomerId(e.target.value.trim())
								}
							/>
						</Grid>
						<Grid item xs={5}>
							<TextField
								fullWidth
								label="New Domain"
								variant="outlined"
								value={newDomain}
								onChange={(e) =>
									setNewDomain(e.target.value.trim())
								}
							/>
						</Grid>
						<Grid item xs={2}>
							<Button
								variant="contained"
								color="secondary"
								onClick={handleDomainChange}
								disabled={isLoading}
								fullWidth
							>
								Update
							</Button>
						</Grid>
					</Grid>
				</StyledDiv>
			</TabPanel>

			{/* Bulk Update Email Domains */}
			<TabPanel value={activeTab} index={4}>
				<StyledDiv>
					<Typography variant="h6">
						Bulk Update Email Domains
					</Typography>
					<Grid container alignItems="center" spacing={2}>
						<Grid item xs={8}>
							<StyledInput
								type="file"
								onChange={handleDomainUpdateFileChange}
								disableUnderline
							/>
						</Grid>
						<Grid item xs={4}>
							<StyledButton
								variant="contained"
								onClick={handleBulkUpdateDomains}
								disabled={isLoading}
							>
								Bulk Update Domains
							</StyledButton>
						</Grid>
					</Grid>
				</StyledDiv>
				{/* Optionally display results here */}
			</TabPanel>

			<TabPanel value={activeTab} index={5}>
				<StyledDiv>
					<Typography variant="h6">Local Recording Status</Typography>
					<Grid container alignItems="center" spacing={2}>
						<Grid item xs={8}>
							<TextField
								fullWidth
								label="Enter user email"
								variant="outlined"
								value={recordingStatusEmail}
								onChange={(e) =>
									setRecordingStatusEmail(
										e.target.value.trim()
									)
								}
							/>
						</Grid>
						<Grid item xs={4}>
							<Button
								variant="contained"
								onClick={handleFetchRecordingStatus}
								disabled={isLoading || !recordingStatusEmail}
								fullWidth
							>
								{isLoading ? (
									<CircularProgress size={24} />
								) : (
									'Fetch Status'
								)}
							</Button>
						</Grid>
					</Grid>
					{recordingStatus && (
						<RecordingStatusDisplay status={recordingStatus} />
					)}
				</StyledDiv>
			</TabPanel>
			<TabPanel value={activeTab} index={6}>
				<SecondaryEmailUpdatePage />
			</TabPanel>

			<OperationFeedback />

			<OperationFeedback />

			{bulkUpdateResults && (
				<Grid
					item
					xs={12}
					style={{
						padding: '20px',
						backgroundColor: '#f5f5f5',
						borderRadius: '8px',
						borderLeft: '5px solid #3f51b5',
						marginTop: '20px',
					}}
				>
					<Typography
						variant="h5"
						gutterBottom
						style={{ fontWeight: 'bold', color: '#3f51b5' }}
					>
						Bulk Update Results
					</Typography>
					<Typography
						variant="subtitle1"
						style={{ marginLeft: '10px', fontWeight: 'bold' }}
					>
						Matched:{' '}
						<span style={{ color: '#4caf50' }}>
							{bulkUpdateResults.matchedCount}
						</span>
						, Modified:{' '}
						<span style={{ color: '#4caf50' }}>
							{bulkUpdateResults.modifiedCount}
						</span>
					</Typography>
					{bulkUpdateResults.modifiedCount <
						bulkUpdateResults.matchedCount && (
						<Typography
							variant="body2"
							style={{
								marginLeft: '10px',
								color: '#ff9800',
								marginTop: '10px',
							}}
						>
							Note: Not all matched emails were updated. This
							indicates that some emails may not have been changed
							due to various reasons, such as validation issues.
						</Typography>
					)}
				</Grid>
			)}

			{isLoading && (
				<Grid item style={{ textAlign: 'center' }}>
					<CircularProgress />
				</Grid>
			)}
		</Grid>
	);
}

export default UserEmail;
