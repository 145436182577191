import { TextField } from '@mui/material';
import {
  customStyles, SECONDARY_EMAIL_UPDATE_MODE,
  SECONDARY_EMAIL_UPDATE_OPTIONS,
} from 'components/customerSupport/helper';
import SecondaryEmailUpdateResponsePage
  from 'components/customerSupport/secondaryEmail/SecondaryEmailUpdateResponsePage';
import UpdateBulkUsers from 'components/customerSupport/secondaryEmail/UpdateBulkUsers';
import UpdateSingleUser from 'components/customerSupport/secondaryEmail/UpdateSingleUser';
import { postJson } from 'network';
import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { validateEmailDomain } from 'utils/helpers';
import { getAllCustomers } from 'utils/network';
import { getAllUsersForCustomer } from 'utils/network/customers';

function SecondaryEmailUpdatePage() {
  const [customers, setCustomers] = useState([]);
  const [allUsers, setAllUsers] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState();
  const [customerPocEmail, setCustomerPocEmail] = useState('');
  const [updateMode, setUpdateMode] = useState(SECONDARY_EMAIL_UPDATE_OPTIONS[0]);
  const [updateResponse, setUpdateResponse] = useState(null);

  const fetchUsersForCustomer = async () => {
    const customerId = selectedCustomer?.value;
    const users = await getAllUsersForCustomer(customerId);
    const userOptions = users.map((user) => ({ label: user.email_id, value: user._id, ...user }));
    setAllUsers(userOptions);
  };

  const updateSecondaryEmails = async (userIdsToSecondaryEmailsMap) => {
    const { isValid } = validateEmailDomain(customerPocEmail, selectedCustomer.authorized_domains);
    if (!isValid) {
      alert('Invalid Customer POC email');
      return;
    }
    const response = await postJson('/support_tools/secondary-emails', {
      userIdsToSecondaryEmailsMap,
      customerId: selectedCustomer._id,
      customerPocEmail,
    });
    const respJSON = await response.json();
    setUpdateResponse(respJSON);
  };

  useEffect(() => {
    const fetchCustomers = async () => {
      const allCustomerOptions = await getAllCustomers();
      setCustomers(allCustomerOptions);
    };
    fetchCustomers();
  }, []);

  useEffect(() => {
    if (!selectedCustomer) return;
    fetchUsersForCustomer();
  }, [selectedCustomer]);

  const handleClearForm = () => {
    fetchUsersForCustomer();
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <div style={{ display: 'flex', gap: '36px' }}>
        <div style={{ display: 'flex', flexDirection: 'column', width: '50%' }}>
          Customer Name
          <Select
            options={customers}
            value={selectedCustomer}
            onChange={setSelectedCustomer}
            isSearchable
            placeholder="Select Customer"
            styles={customStyles}
          />
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', width: '50%' }}>
          Email Update Mode
          <Select
            options={SECONDARY_EMAIL_UPDATE_OPTIONS}
            value={updateMode}
            onChange={setUpdateMode}
            placeholder="Choose update mode"
            styles={customStyles}
            isDisabled={!selectedCustomer?._id}
          />
        </div>
      </div>
      <div>
        <TextField
          style={{ width: '40rem', marginTop: '1.75rem' }}
          InputLabelProps={{ shrink: true }}
          label="Requested by (Enter email address of point of contact from Customer who requested to add secondary emails)*"
          value={customerPocEmail}
          onChange={(e) => setCustomerPocEmail(e.target.value)}
        />
      </div>
      {updateMode.value === SECONDARY_EMAIL_UPDATE_MODE.SINGLE_USER
        ? (
          <UpdateSingleUser
            customer={selectedCustomer}
            allUsers={allUsers}
            updateSecondaryEmails={updateSecondaryEmails}
            clearForm={handleClearForm}
          />
        )
        : (
          <UpdateBulkUsers
            customer={selectedCustomer}
            allUsers={allUsers}
            updateSecondaryEmails={updateSecondaryEmails}
            clearForm={handleClearForm}
          />
        )}
      {updateResponse && (
        <SecondaryEmailUpdateResponsePage
          updateResponse={updateResponse}
          setUpdateResponse={setUpdateResponse}
        />
      )}
    </div>
  );
}
export default SecondaryEmailUpdatePage;
