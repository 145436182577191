import { Button, Modal } from '@mui/material';
import React from 'react';

const ConfirmCustomerDataDeletionDialog = ({ open, handleClose, onConfirm }) => {

  return (
    <Modal
      open={open}
      onClose={handleClose}
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          backgroundColor: 'white',
          width: '50%',
          outline: 'none',
          padding: '3rem',
          gap: '1rem',
        }}
      >
        <div
          style={{
            marginBottom: '1rem',
          }}
        >
          {'Deleting customer data will delete all the data associated with the customer. Are you sure you want to proceed?'}
        </div>
        <div
          style={{
            color: 'red',
            marginBottom: '1rem',
          }}
        >
          {'Warning: This action is NON Recoverable'}
        </div>
        <Button
          variant="contained"
          style={{ width: '12rem' }}
          onClick={onConfirm}
        >
          Confirm Deletion
        </Button>
      </div>
    </Modal>
  );
};

export default ConfirmCustomerDataDeletionDialog;