import { EMAIL_REGEX_PATTERN } from 'utils/constants';

export function validateEmail(email) {
  return EMAIL_REGEX_PATTERN.test(email);
}

export function displayStr(item) {
  const str = item.charAt(0).toUpperCase() + item.slice(1).toLowerCase();
  return str.replace(/_/g, ' ');
}

export const validateEmailDomain = (email, authorizedDomains) => {
  if (!email || email.trim() === '') return { isValid: false, error: 'Email cannot be empty.' };
  const emailDomain = email.split('@')?.[1];
  if (!emailDomain || !authorizedDomains.includes(emailDomain)) {
    return {
      isValid: false,
      error: `The domain in the provided email address is not authorized for this customer. Authorized domains are: \n${authorizedDomains.join(',\n')}`,
    };
  }
  return { isValid: true };
};
