import React, { useState, useEffect } from 'react';
import {
    TextField,
    IconButton,
    Typography,
    CircularProgress,
    CardContent,
    Grid,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    ListSubheader,
} from '@mui/material';
import ObjectTreeView from './ObjectTreeView';
import { getData } from './network';
import CustomerSelect from './CustomerSelect';
import { select } from './util';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import PieChartIcon from '@mui/icons-material/PieChart';
import MyDoughnut from './MyDoughnut';

function MyListSubheader(props) {
    return <ListSubheader {...props} />;
}
MyListSubheader.muiSkipListHighlight = true;

export default function CustomerCallStatusesDist(props) {
    const dialerOptions = [
        'AIRCALL_RECORDING',
        'DIALPAD',
        'DIALPAD_LIVE',
        'FRESHCALLER',
        'FRESHCALLER_LIVE',
        'FRONTSPIN',
        'GROOVE',
        'HUBSPOT',
        'HUBSPOT_LIVE',
        'ORUM',
        'OUTREACH',
        'RINGCENTRAL',
        'RINGCENTRAL_MEETING',
        'SALESLOFT',
        'TALKDESK',
    ];

    const recorderOptions = [
        'GOOGLE_MEET',
        'MS_TEAMS',
        'ZOOM',
        'ZOOM_PHONE',
    ];

    const [selectedCustomerId, setSelectedCustomerId] = useState(null);
    const [selectedCRMOption, setSelectedCRMOption] = useState('');
    const [selectedCallTypeOption, setSelectedCallTypeOption] = useState('');
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [isLoading, setIsLoading] = useState(false);
    const [responseMessage, setResponseMessage] = useState('');
    const [callStatusDist, setCallStatusDist] = useState(null);
    const [callStatusStats, setCallStatusStats] = useState(null);

    const handleStartDateChange = (newValue) => {
        setStartDate(newValue);
    };

    const handleEndDateChange = (newValue) => {
        setEndDate(newValue);
    };

    const setResult = (callStatusDist = null, error = null) => {
        setCallStatusDist(callStatusDist)
        setResponseMessage(error);

        if (callStatusDist) {
            let stats = {}
            callStatusDist.forEach((o) => {
                stats[o['_id']] = o['count']
            })
            setCallStatusStats(stats)
        } else {
            setCallStatusStats(null)
        }
    };

    const handleSubmit = async () => {
        if (!selectedCustomerId || !selectedCallTypeOption) {
            setResult(null, 'Select both Customer and Call type first');
            return
        }


        setIsLoading(true);
        try {
            const endpoint = '/support_tools/fetch-call-status-distribution';
            const params = {
                customerId: selectedCustomerId,
                call_type: selectedCallTypeOption,
                since: startDate.toISOString(),
                till: endDate.toISOString(),
            };
            const response = await getData(endpoint, params);
            if (!response.ok) {
                throw new Error('Failed to fetch call status distribution');
            }
            const resultText = await response.json();
            setResult(resultText);
        } catch (error) {
            console.error('Error occurred:', error);
            setResult(null, error.toString());
        } finally {
            setIsLoading(false);
        }
    };

    function displayStr(item) {
        const str = item.charAt(0).toUpperCase() + item.slice(1).toLowerCase();
        return str.replace(/_/g, ' ');
    }

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Typography variant="h4" gutterBottom>
                Call Status Distribution
            </Typography>
            <Grid
                container
                alignItems="center"
                justifyContent="center"
                spacing={2}
            >
                <Grid item xs={12} style={{ boxShadow: 'none' }}>
                    <CardContent>
                        <Grid
                            container
                            spacing={2}
                            alignItems="center"
                            justifyContent="space-around"
                        >
                            <Grid item xs={12} md={2} sx={{ flexGrow: 1 }}>
                                <CustomerSelect
                                    onSelect={setSelectedCustomerId}
                                    width='100%'
                                />
                            </Grid>
                            <Grid item xs={12} md={2} sx={{ flexGrow: 1 }}>
                                <FormControl
                                    variant="standard"
                                    style={{ opacity: 1, width: '100%' }}
                                >
                                    {<InputLabel>{'Call type'}</InputLabel>}
                                    {<Select
                                        value={selectedCallTypeOption}
                                        multiple={null}
                                        onChange={(e) => setSelectedCallTypeOption(e.target.value)}
                                    >
                                        <MyListSubheader>Dialers</MyListSubheader>
                                        {dialerOptions.map((item, i) => (
                                            <MenuItem key={i} value={item}>
                                                {displayStr(item)}
                                            </MenuItem>
                                        ))}
                                        <MyListSubheader>Recorders</MyListSubheader>
                                        {recorderOptions.map((item, i) => (
                                            <MenuItem key={i} value={item}>
                                                {displayStr(item)}
                                            </MenuItem>
                                        ))}
                                    </Select>}
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} md={2} sx={{ flexGrow: 1 }}>
                                <DateTimePicker
                                    label="Start Date"
                                    value={startDate}
                                    onChange={handleStartDateChange}
                                    renderInput={(props) => (
                                        <TextField {...props} />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12} md={2} sx={{ flexGrow: 1 }}>
                                <DateTimePicker
                                    label="End Date"
                                    value={endDate}
                                    onChange={handleEndDateChange}
                                    renderInput={(props) => (
                                        <TextField {...props} />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12} md={2} sx={{ flexGrow: 1 }}>
                                {isLoading ? (
                                    <CircularProgress />
                                ) : (
                                    <IconButton
                                        color="primary"
                                        onClick={handleSubmit}
                                    >
                                        <PieChartIcon />
                                    </IconButton>
                                )}
                            </Grid>
                        </Grid>
                        <Typography variant="h6" align="center">
                            {responseMessage}
                        </Typography>
                        <Grid
                            container
                            spacing={2}
                            alignItems="flex-start"
                            justifyContent="space-around"
                        >
                            <Grid item xs={12} md={4} sx={{ flexGrow: 1 }}>
                                <ObjectTreeView obj={callStatusDist} name="callStatusDist" />
                            </Grid>
                            <Grid item xs={12} md={4} sx={{ flexGrow: 1 }}>
                                {callStatusStats && (
                                    <MyDoughnut
                                        title="Call status distribution"
                                        stats={callStatusStats}
                                    />
                                )}
                            </Grid>
                        </Grid>
                    </CardContent>
                </Grid>
            </Grid >
        </LocalizationProvider >
    );
}
