import React from 'react';
import {
  Modal, Table, TableBody, TableCell, TableHead, TableRow, Typography, Button,
} from '@mui/material';

function SecondaryEmailUpdateResponsePage({ updateResponse, setUpdateResponse }) {
  const downloadCSV = () => {
    const headers = ['Email ID', 'Accepted Emails', 'Duplicate/Rejected Emails'];
    const rows = Object.keys(updateResponse.acceptedSecondaryEmailsMap).map((emailId) => [
      emailId,
      updateResponse.acceptedSecondaryEmailsMap[emailId].join('; '),
      updateResponse.rejectedSecondaryEmailsMap[emailId].join('; '),
    ]);

    let csvContent = 'data:text/csv;charset=utf-8,';
    csvContent += `${headers.join(',')}\n`;
    rows.forEach((row) => {
      csvContent += `${row.join(',')}\n`;
    });

    const encodedUri = encodeURI(csvContent);
    const link = document.createElement('a');
    link.setAttribute('href', encodedUri);
    link.setAttribute('download', 'secondary_email_update.csv');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <Modal open={!!updateResponse} onClose={() => setUpdateResponse(null)}>
      <div style={{
        padding: '20px', backgroundColor: 'white', margin: 'auto', marginTop: '10%', width: '75%', minHeight: '50%',
      }}
      >
        <Typography variant="h6">Update Response</Typography>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Email ID</TableCell>
              <TableCell>Accepted Emails</TableCell>
              <TableCell>Invalid/Rejected Emails</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.keys(updateResponse.acceptedSecondaryEmailsMap).map((emailId) => (
              <TableRow key={emailId}>
                <TableCell>{emailId}</TableCell>
                <TableCell>
                  {updateResponse.acceptedSecondaryEmailsMap[emailId].join('; ')}
                </TableCell>
                <TableCell>
                  {updateResponse.rejectedSecondaryEmailsMap[emailId].join('; ')}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <Button onClick={downloadCSV} style={{ marginTop: '20px' }}>Download CSV</Button>
      </div>
    </Modal>
  );
}

export default SecondaryEmailUpdateResponsePage;
