import {
  Button, Chip, FormControl, Input, InputLabel,
} from '@mui/material';
import { addSecondaryEmailToUser, customStyles, removeSecondaryEmailFromUser } from 'components/customerSupport/helper';
import { postJson } from 'network';
import React, { useState } from 'react';
import Select from 'react-select';
import { validateEmailDomain } from 'utils/helpers';

function UpdateSingleUser({
  customer, allUsers, clearForm, updateSecondaryEmails,
}) {
  const [selectedUser, setSelectedUser] = useState(null);
  const [newSecondaryEmail, setNewSecondaryEmail] = useState('');
  const [loading, setLoading] = useState(false);

  const handleClearForm = () => {
    setSelectedUser(null);
    setNewSecondaryEmail('');
    clearForm();
  };

  const handleAddSecondaryEmail = (event) => {
    if (event.key !== 'Enter') return;
    const email = event.target.value;
    const { isValid, error } = validateEmailDomain(email, customer.authorized_domains);
    // to do check for duplicate email
    if (!isValid) {
      alert(error);
      return;
    }
    setSelectedUser((prevUser) => addSecondaryEmailToUser(prevUser, email));
    setNewSecondaryEmail('');
  };

  const removeSecondaryEmail = (emailId) => {
    setSelectedUser((prevUser) => removeSecondaryEmailFromUser(prevUser, emailId));
  };

  const handleUpdateSecondaryEmail = async () => {
    // deconstructed to array since set in not serializable
    setLoading(true);
    await updateSecondaryEmails({ [selectedUser._id]: [...selectedUser.secondary_email_id] });
    setLoading(false);
    handleClearForm();
  };

  return (
    <div>
      <div style={{ display: 'flex', flexDirection: 'column', marginTop: '1rem' }}>
        Select user (by email)
        <Select
          options={allUsers}
          value={selectedUser}
          onChange={setSelectedUser}
          placeholder="Select user"
          styles={customStyles}
        />
      </div>
      {selectedUser?._id
        && (
          <FormControl variant="standard" className="w-full" style={{ marginTop: '1rem' }}>
            <InputLabel>Add new secondary email</InputLabel>
            <Input
              value={newSecondaryEmail}
              onChange={(e) => setNewSecondaryEmail(e.target.value)}
              onKeyDown={handleAddSecondaryEmail}
            />
            <div>
              {selectedUser?.secondary_email_id
              && [...selectedUser?.secondary_email_id]?.map((emailId) => (
                <Chip
                  key={emailId}
                  label={emailId}
                  onDelete={() => removeSecondaryEmail(emailId)}
                />
              ))}
            </div>
          </FormControl>
        )}
      <div style={{ display: 'flex', marginTop: '8rem', justifyContent: 'flex-end' }}>
        <Button variant="contained" disabled={!selectedUser?._id || loading} onClick={handleUpdateSecondaryEmail}>Submit</Button>
      </div>
    </div>
  );
}
export default UpdateSingleUser;
