import React, { useState, useEffect, useMemo } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { getData } from 'network';
import {
	Typography,
	Paper,
	Box,
	Select,
	MenuItem,
	FormControl,
	InputLabel,
	IconButton,
	Dialog,
	DialogContent,
	DialogTitle,
	IconButton as CloseButton,
} from '@mui/material';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import { mapJobSubType } from 'util';
import CloseIcon from '@mui/icons-material/Close';
import { ModelsTab } from './smartTopicsUtils';

import InfoIcon from '@mui/icons-material/Info';

const canShowDownloadButton = (status) => status === 'POST_PROCESSING_DONE';

const handleDownloadOnPostProcessingDone = (
	job_config,
	sub_type,
	handleDownloadClick,
	customerId
) => {
	const modelId = job_config?.model?._id?.toString();
	console.log('Model ID:', modelId);
	handleDownloadClick(job_config?.version, modelId, sub_type, customerId);
};

const columns = (handleDownloadClick, customerId, handleModelView) => [
	{
		field: 'actions',
		headerName: 'Output',
		width: 90,
		align: 'center',
		headerAlign: 'center',
		renderCell: (params) => {
			const { status, sub_type, job_config } = params.row;
			return canShowDownloadButton(status) ? (
				<IconButton
					color="primary"
					onClick={(e) => {
						e.stopPropagation();
						handleDownloadOnPostProcessingDone(
							job_config,
							sub_type,
							handleDownloadClick,
							customerId
						);
					}}
				>
					<CloudDownloadIcon />
				</IconButton>
			) : null;
		},
	},
	{
		field: '_id',
		headerName: 'Job ID',
		width: 350,
		headerAlign: 'center',
		align: 'left',
		renderCell: (params) => (
			<Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
				{params.row.model_id !== 'N/A' && (
					<IconButton
						size="small"
						onClick={(e) => {
							e.stopPropagation();
							handleModelView(params.row.model_id);
						}}
						sx={{ ml: 1 }}
					>
						<InfoIcon fontSize="small" />
					</IconButton>
				)}
				<Typography
					variant="body2"
					sx={{ fontWeight: 'bold', cursor: 'pointer' }}
					onClick={(e) => {
						e.stopPropagation();
						handleJobClick(params.row._id);
					}}
				>
					{params.value}
				</Typography>
			</Box>
		),
	},

	{
		field: 'model_id',
		headerName: 'Model ID',
		width: 220,
		align: 'center',
		headerAlign: 'center',
		renderCell: (params) => (
			<Typography variant="body2" sx={{ fontWeight: 'bold' }}>
				{params.value}
			</Typography>
		),
	},
	{
		field: 'creation_date',
		headerName: 'Creation Date',
		width: 190,
		headerAlign: 'center',
		align: 'center',
	},
	{
		field: 'start_time',
		headerName: 'Start Time',
		width: 155,
		headerAlign: 'center',
		align: 'center',
	},
	{
		field: 'end_time',
		headerName: 'End Time',
		width: 155,
		headerAlign: 'center',
		align: 'center',
	},
	{
		field: 'status',
		headerName: 'Status',
		width: 190,
		align: 'center',
		headerAlign: 'center',
	},
	{
		field: 'dataset_id',
		headerName: 'Dataset ID',
		width: 320,
		align: 'center',
		headerAlign: 'center',
	},
];

function formatTimestamp(timestamp) {
	const date = new Date(timestamp);
	const formattedDate = date.toISOString().split('T')[0];
	const formattedTime = date.toTimeString().split(' ')[0];
	return `${formattedDate} ${formattedTime}`;
}

function JobsTable({ searchText, customerId, successResponse, customerName }) {
	const [jobs, setJobs] = useState([]);
	const [statusFilter, setStatusFilter] = useState('');
	const [selectedModelId, setSelectedModelId] = useState(null);
	const [isModelDialogOpen, setIsModelDialogOpen] = useState(false);
	const [selectedJobId, setSelectedJobId] = useState(null);

	const handleCloseModelDialog = () => {
		setIsModelDialogOpen(false);
		setSelectedModelId(null);
	};

	const handleModelView = (modelId) => {
		setSelectedModelId(modelId);
		setIsModelDialogOpen(true);
	};

	const handleJobClick = (jobId) => {
		const selectedJob = jobs.find((job) => job._id === jobId);
		if (selectedJob) {
			const modelId = selectedJob.model_id;
			setSelectedModelId(modelId);
			setIsModelDialogOpen(true);
		}
	};

	const fetchJobs = async () => {
		try {
			const response = await getData(`/smart_topics/jobs/${customerId}`);
			const data = await response.json();
			console.log('Fetched jobs:', data);

			const jobsData = data.map((job, index) => ({
				id: index,
				_id: job._id || 'N/A',
				creation_date: formatTimestamp(job.creation_date),
				start_time: formatTimestamp(
					job.job_config?.dataset?.start_time
				),
				end_time: formatTimestamp(job.job_config?.dataset?.end_time),
				object_type: job.job_config?.dataset?.object_type || 'N/A',
				status: job.status || 'N/A',
				sub_type: mapJobSubType(job.sub_type) || 'N/A',
				model_id: job.job_config?.model?._id || 'N/A',
				dataset_id: job.job_config?.dataset?._id || 'N/A',
				job_config: job.job_config || {},
			}));

			jobsData.sort(
				(a, b) => new Date(b.creation_date) - new Date(a.creation_date)
			);

			setJobs(jobsData);
		} catch (error) {
			console.error('Error fetching jobs:', error);
		}
	};

	useEffect(() => {
		fetchJobs();
	}, [customerId]);

	useEffect(() => {
		if (successResponse) {
			fetchJobs();
		}
	}, [successResponse]);

	const filteredJobs = jobs.filter(
		(job) =>
			job._id.toLowerCase().includes(searchText.toLowerCase()) &&
			(statusFilter === '' || job.status === statusFilter)
	);

	const handleStatusChange = (event) => {
		setStatusFilter(event.target.value);
	};

	const handleDownloadClick = async (
		version,
		modelId,
		sub_type,
		customerId
	) => {
		try {
			console.log('Initiating download with parameters:', {
				version,
				modelId,
				sub_type,
				customerId,
			});
			const response = await getData(
				`/smart_topics/generate-presigned-url`,
				{
					customerId,
					sub_type,
					model_id: modelId?.toString(),
					version,
				}
			);
			const { url } = await response.json();
			console.log('Presigned URL:', url);
			window.open(url, '_blank');
		} catch (error) {
			console.error('Error fetching presigned URL:', error);
		}
	};

	const memoizedColumns = useMemo(
		() => columns(handleDownloadClick, customerId, handleModelView),
		[handleDownloadClick, customerId, handleModelView]
	);

	return (
		<Box>
			{successResponse && (
				<Paper
					style={{
						padding: '1rem',
						marginTop: '1rem',
						marginBottom: '3rem',
					}}
				>
					<Typography variant="h6" gutterBottom>
						Latest Training Job Result:
					</Typography>
					<Typography variant="body1" component="pre">
						{successResponse}
					</Typography>
				</Paper>
			)}
			<FormControl fullWidth style={{ marginBottom: '1rem' }}>
				<InputLabel id="status-filter-label">
					Filter by Status
				</InputLabel>
				<Select
					labelId="status-filter-label"
					id="status-filter"
					value={statusFilter}
					label="Filter by Status"
					onChange={handleStatusChange}
				>
					<MenuItem value="">All</MenuItem>
					<MenuItem value="COMPLETED">COMPLETED</MenuItem>
					<MenuItem value="IN_PROGRESS">IN_PROGRESS</MenuItem>
					<MenuItem value="CREATED">CREATED</MenuItem>
					<MenuItem value="INITIATED">INITIATED</MenuItem>
					<MenuItem value="IN_PRE_PROCESSING">
						IN_PRE_PROCESSING
					</MenuItem>
					<MenuItem value="CANCELLED">CANCELLED</MenuItem>
					<MenuItem value="PAUSED">PAUSED</MenuItem>
					<MenuItem value="IN_POST_PROCESSING">
						IN_POST_PROCESSING
					</MenuItem>
					<MenuItem value="POST_PROCESSING_DONE">
						POST_PROCESSING_DONE
					</MenuItem>
					<MenuItem value="ERROR">ERROR</MenuItem>
				</Select>
			</FormControl>
			<div style={{ height: 400, width: '100%', marginBottom: '2rem' }}>
				<DataGrid
					rows={filteredJobs}
					columns={memoizedColumns}
					pageSize={5}
					rowsPerPageOptions={[5]}
					checkboxSelection={false}
				/>
			</div>
			<Dialog
				open={isModelDialogOpen}
				onClose={handleCloseModelDialog}
				maxWidth="xl"
				fullScreen
			>
				<DialogTitle>
					<Box
						display="flex"
						justifyContent="space-between"
						alignItems="center"
						pageSize={5}
					>
						<CloseButton
							onClick={handleCloseModelDialog}
							aria-label="close"
						>
							<CloseIcon />
						</CloseButton>
					</Box>
				</DialogTitle>
				<DialogContent>
					{isModelDialogOpen && (
						<ModelsTab
							modelId={selectedModelId}
							customerId={customerId}
							customerName={customerName}
							onClose={handleCloseModelDialog}
						/>
					)}
				</DialogContent>
			</Dialog>
		</Box>
	);
}

export default JobsTable;
