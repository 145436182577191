import React, { useState, useEffect } from 'react';
import {
	Typography,
	Box,
	TextField,
	Button,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	Divider,
	Grid,
	Snackbar,
	Alert,
	Accordion,
	AccordionSummary,
	AccordionDetails,
	Switch,
	FormControlLabel,
	Chip,
} from '@mui/material';

import AnalyticsIcon from '@mui/icons-material/Analytics';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SettingsIcon from '@mui/icons-material/Settings';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import CircularProgress from '@mui/material/CircularProgress';

import JobsTable from './JobsTable';
import CustomerSelect from './CustomerSelect';
import { useStyles, fetchCustomerData, trainModel } from './smartTopicsUtils';
import {
	useModelTraining,
	DATE_RANGE_PRESETS,
	PARAMETER_PRESETS,
	PARAMETER_DESCRIPTIONS,
	validateTrainingParams,
	processModelParams,
	arrayToString,
	validateModelParams,
} from './topicModelingHelpers.js';

function SmartTopics() {
	const classes = useStyles();
	const [searchText, setSearchText] = useState('');
	const [openTrainDialog, setOpenTrainDialog] = useState(false);
	const [selectedCustomerId, setSelectedCustomerId] = useState('');
	const [selectedCustomerName, setSelectedCustomerName] = useState('');
	const [objectType] = useState('CALL');
	const [topicType] = useState('SMART_TOPIC');
	const [jobVersion] = useState('v1');
	const [runType] = useState("MANUAL_INTERNAL")
	const [startTime, setStartTime] = useState(null);
	const [endTime, setEndTime] = useState(null);
	const [customers, setCustomers] = useState([]);
	const [snackbarMessage, setSnackbarMessage] = useState('');
	const [snackbarOpen, setSnackbarOpen] = useState(false);
	const [snackbarSeverity, setSnackbarSeverity] = useState('success');
	const [successResponse, setSuccessResponse] = useState(null);
	const [showAdvancedSettings, setShowAdvancedSettings] = useState(false);
	const [showConfirmDialog, setShowConfirmDialog] = useState(false);

	const {
		isTraining,
		setIsTraining,
		modelParams,
		setModelParams,
		hasUnsavedChanges,
		handleParamChange,
		handleArrayParamChange,
		resetForm,
	} = useModelTraining(PARAMETER_PRESETS.DEFAULT.params);

	useEffect(() => {
		fetchCustomerData().then(setCustomers);
	}, []);

	const handleSearchChange = (event) => {
		setSearchText(event.target.value);
	};

	const handleTrainModelClick = () => {
		setOpenTrainDialog(true);
	};

	const handleCloseWithCheck = () => {
		if (hasUnsavedChanges) {
			setShowConfirmDialog(true);
		} else {
			handleCloseTrainDialog();
		}
	};

	const handleCloseTrainDialog = () => {
		setOpenTrainDialog(false);
		setShowConfirmDialog(false);
	};

	const getFieldValue = (category, field) => {
		if (category) {
			const value = modelParams[category][field];
			return typeof value === 'string' ? value : arrayToString(value);
		}
		return modelParams[field];
	};

	const handleTrainModel = async () => {
		const validation = validateTrainingParams(startTime, endTime);
		if (!validation.isValid) {
			setSnackbarMessage(validation.message);
			setSnackbarSeverity('error');
			setSnackbarOpen(true);
			return;
		}

		try {
			const processedParams = processModelParams(modelParams);
			validateModelParams(processedParams);
			setIsTraining(true);

			const result = await trainModel(
				startTime,
				endTime,
				objectType,
				topicType,
				jobVersion,
				selectedCustomerId,
				runType,
				processedParams,
			);

			setSnackbarMessage(result.message);
			setSnackbarSeverity(result.severity);
			setSuccessResponse(result.message);
			handleCloseTrainDialog();
		} catch (error) {
			if (error.name === 'ValidationError') {
				setSnackbarMessage(error.message);
			} else {
				setSnackbarMessage('Error training model: ' + error.message);
			}
			setSnackbarSeverity('error');
		} finally {
			setIsTraining(false);
			setSnackbarOpen(true);
		}
	};

	const handleSnackbarClose = () => {
		setSnackbarOpen(false);
	};

	const handleCustomerSelect = (customerId, customerName) => {
		setSelectedCustomerId(customerId);
		setSelectedCustomerName(customerName);
	};

	const renderDateRangePresets = () => (
		<Box sx={{ mb: 2 }}>
			<Typography variant="subtitle2" sx={{ mb: 1 }}>
				Quick Ranges:
			</Typography>
			{DATE_RANGE_PRESETS.map((preset) => (
				<Chip
					key={preset.label}
					label={preset.label}
					onClick={() => {
						const end = new Date();
						const start = new Date();
						start.setDate(start.getDate() + preset.start);
						setStartTime(start);
						setEndTime(end);
					}}
					sx={{ mr: 1 }}
				/>
			))}
		</Box>
	);

	const renderParameterField = (category, param) => (
		<TextField
			label={param}
			value={getFieldValue(category, param)}
			onChange={(e) =>
				handleArrayParamChange(category, param, e.target.value)
			}
			fullWidth
			helperText={
				PARAMETER_DESCRIPTIONS[param]?.helperText ||
				'No description available'
			}
		/>
	);

	return (
		<>
			{!selectedCustomerId ? (
				<Box
					display="flex"
					flexDirection="column"
					alignItems="center"
					justifyContent="center"
				>
					<Typography
						variant="h4"
						component="h1"
						color="primary"
						fontWeight="bold"
						mb={4}
					>
						Select a Customer
					</Typography>
					<CustomerSelect
						customers={customers}
						onSelect={handleCustomerSelect}
						width="300px"
					/>
				</Box>
			) : (
				<div className={classes.recorderExperiments}>
					<Box className={classes.content}>
						<Box
							display="flex"
							flexDirection="column"
							alignItems="center"
							mb={6}
						>
							<Box display="flex" alignItems="center" mb={2}>
								<AnalyticsIcon
									sx={{
										fontSize: 40,
										color: 'primary.main',
										mr: 2,
									}}
								/>
								<Typography
									variant="h4"
									component="h4"
									color="primary"
									fontWeight="bold"
								>
									Topic Model Generation
								</Typography>
							</Box>
							<Typography
								variant="subtitle1"
								color="text.primary"
								align="center"
							>
								Jobs Analytics for Customer:{' '}
								<Typography
									component="span"
									variant="subtitle1"
									color="secondary"
									style={{ fontWeight: 'bold' }}
								>
									{selectedCustomerName}
								</Typography>
							</Typography>
							<Divider
								sx={{
									width: '80%',
									mt: 2,
									borderColor: 'primary.main',
								}}
							/>
						</Box>

						<TextField
							label="Search Jobs"
							variant="outlined"
							value={searchText}
							onChange={handleSearchChange}
							size="small"
							sx={{ width: '40%', mb: 3 }}
						/>
						<Button
							variant="contained"
							sx={{ ml: 3 }}
							onClick={handleTrainModelClick}
						>
							Train Model
						</Button>

						<JobsTable
							searchText={searchText}
							customerId={selectedCustomerId}
							successResponse={successResponse}
						/>
					</Box>
				</div>
			)}

			<Dialog
				open={openTrainDialog}
				onClose={handleCloseWithCheck}
				maxWidth="lg"
				fullWidth
			>
				<DialogTitle>
					<Box
						display="flex"
						justifyContent="center"
						alignItems="center"
						width="100%"
						mb={3}
					>
						<Typography
							variant="h4"
							component="h4"
							color="primary"
							fontWeight="bold"
							textAlign="center"
						>
							Train Model for Customer: {selectedCustomerName}
						</Typography>
					</Box>
				</DialogTitle>
				<DialogContent className={classes.dialogContent}>
					<LocalizationProvider dateAdapter={AdapterDateFns}>
						<Grid container spacing={3}>
							<Grid item xs={12}>
								{renderDateRangePresets()}
							</Grid>

							<Grid item xs={12} md={6}>
								<Box
									display="flex"
									flexDirection="column"
									gap={2}
								>
									<DateTimePicker
										label="Start Time"
										value={startTime}
										onChange={(date) => setStartTime(date)}
										maxDate={endTime}
										renderInput={(props) => (
											<TextField {...props} />
										)}
									/>
									<DateTimePicker
										label="End Time"
										value={endTime}
										onChange={(date) => setEndTime(date)}
										maxDate={new Date()}
										renderInput={(props) => (
											<TextField {...props} />
										)}
									/>
								</Box>
							</Grid>

							<Grid item xs={12} md={6}>
								<Box
									display="flex"
									flexDirection="column"
									gap={2}
								>
									<TextField
										label="Object Type"
										value={objectType}
										disabled
										fullWidth
									/>
									<TextField
										label="Topic Type"
										value={topicType}
										disabled
										fullWidth
									/>
									<TextField
										label="Job Version"
										value={jobVersion}
										disabled
										fullWidth
									/>
									<TextField 
									label = "Run Type"
									value={runType}
									disabled
									fullWidth
									/>
								</Box>
							</Grid>

							<Grid item xs={12}>
								<FormControlLabel
									control={
										<Switch
											checked={showAdvancedSettings}
											onChange={(e) =>
												setShowAdvancedSettings(
													e.target.checked
												)
											}
										/>
									}
									label={
										<Box display="flex" alignItems="center">
											<SettingsIcon sx={{ mr: 1 }} />
											<Typography>
												Advanced Settings
											</Typography>
										</Box>
									}
								/>
							</Grid>

							{showAdvancedSettings && (
								<Grid item xs={12}>
									<Accordion>
										<AccordionSummary
											expandIcon={<ExpandMoreIcon />}
										>
											<Typography fontWeight="bold">
												HDBSCAN Parameters
											</Typography>
										</AccordionSummary>
										<AccordionDetails>
											<Grid container spacing={2}>
												{Object.entries(
													modelParams.hdbscanHyperparams
												).map(([param]) => (
													<Grid
														item
														xs={12}
														sm={6}
														key={param}
													>
														{renderParameterField(
															'hdbscanHyperparams',
															param
														)}
													</Grid>
												))}
											</Grid>
										</AccordionDetails>
									</Accordion>

									<Accordion>
										<AccordionSummary
											expandIcon={<ExpandMoreIcon />}
										>
											<Typography fontWeight="bold">
												UMAP Parameters
											</Typography>
										</AccordionSummary>
										<AccordionDetails>
											<Grid container spacing={2}>
												{Object.entries(
													modelParams.umapHyperparams
												).map(([param]) => (
													<Grid
														item
														xs={12}
														sm={6}
														key={param}
													>
														{renderParameterField(
															'umapHyperparams',
															param
														)}
													</Grid>
												))}
											</Grid>
										</AccordionDetails>
									</Accordion>

									<Accordion>
										<AccordionSummary
											expandIcon={<ExpandMoreIcon />}
										>
											<Typography fontWeight="bold">
												Other Parameters
											</Typography>
										</AccordionSummary>
										<AccordionDetails>
											<Grid container spacing={2}>
												<Grid item xs={12} sm={6}>
													<TextField
														label="Granularity"
														value={
															modelParams.granularity
														}
														disabled
														fullWidth
														helperText="Fixed value: TURN"
													/>
												</Grid>
												<Grid item xs={12} sm={6}>
													<TextField
														label="Min Words Per Turn"
														type="number"
														value={
															modelParams.minWordsPerTurn
														}
														onChange={(e) =>
															handleParamChange(
																'',
																'minWordsPerTurn',
																parseInt(
																	e.target
																		.value
																)
															)
														}
														fullWidth
														helperText="Enter a single integer value"
													/>
												</Grid>
												<Grid item xs={12} sm={6}>
													<TextField
														label="Embedding Dimension"
														type="number"
														value={
															modelParams.embeddingDimension
														}
														onChange={(e) =>
															handleParamChange(
																'',
																'embeddingDimension',
																parseInt(
																	e.target
																		.value
																)
															)
														}
														fullWidth
														helperText="Enter a single integer value"
													/>
												</Grid>
												<Grid item xs={12} sm={6}>
													<TextField
														label="Embedding Model"
														value={
															modelParams.embeddingModel
														}
														onChange={(e) =>
															handleParamChange(
																'',
																'embeddingModel',
																e.target.value
															)
														}
														fullWidth
														helperText="Enter the embedding model name"
													/>
												</Grid>
											</Grid>
										</AccordionDetails>
									</Accordion>
								</Grid>
							)}
						</Grid>
					</LocalizationProvider>
				</DialogContent>
				<DialogActions sx={{ p: 3, justifyContent: 'center' }}>
					<Button
						onClick={handleCloseWithCheck}
						variant="outlined"
						disabled={isTraining}
					>
						Cancel
					</Button>
					<Button
						onClick={handleTrainModel}
						variant="contained"
						disabled={
							isTraining ||
							!selectedCustomerId ||
							!startTime ||
							!endTime
						}
						sx={{ ml: 2 }}
					>
						{isTraining ? (
							<>
								<CircularProgress size={24} sx={{ mr: 1 }} />
								Training...
							</>
						) : (
							'Train Model'
						)}
					</Button>
				</DialogActions>
			</Dialog>

			<Dialog
				open={showConfirmDialog}
				onClose={() => setShowConfirmDialog(false)}
			>
				<DialogTitle>Unsaved Changes</DialogTitle>
				<DialogContent>
					<Typography>
						You have unsaved changes in the form. Are you sure you
						want to close without saving?
					</Typography>
				</DialogContent>
				<DialogActions>
					<Button onClick={() => setShowConfirmDialog(false)}>
						Cancel
					</Button>
					<Button
						onClick={() => {
							resetForm();
							handleCloseTrainDialog();
						}}
						color="error"
					>
						Close Without Saving
					</Button>
				</DialogActions>
			</Dialog>

			<Snackbar
				open={snackbarOpen}
				autoHideDuration={6000}
				onClose={handleSnackbarClose}
				anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
			>
				<Alert
					onClose={handleSnackbarClose}
					severity={snackbarSeverity}
					variant="filled"
				>
					{snackbarMessage}
				</Alert>
			</Snackbar>
		</>
	);
}

export default SmartTopics;