import { useState, useEffect } from 'react';

export const DATE_RANGE_PRESETS = [
	{ label: 'Last 7 Days', start: -7, end: 0 },
	{ label: 'Last 30 Days', start: -30, end: 0 },
	{ label: 'Last 90 Days', start: -90, end: 0 },
];

export const PARAMETER_PRESETS = {
	DEFAULT: {
		name: 'Default',
		params: {
			hdbscanHyperparams: {
				clusterSizes: [3, 4, 5, 6, 7, 8, 9, 10, 12, 15, 17, 20],
				samplesList: [3, 4, 5, 6, 7, 8, 9, 10, 12, 15, 17, 20],
				clusterEpsilons: [0.0],
				clusterMethods: ['leaf'],
			},
			umapHyperparams: {
				umapNComponents: [30],
				umapNEpochs: [],
			},
			minWordsPerTurn: 30,
			embeddingDimension: 512,
			embeddingModel: 'copilot-embedding',
			granularity: 'TURN',
		},
	},
};

export const PARAMETER_DESCRIPTIONS = {
	clusterSizes: {
		label: 'Cluster Sizes',
		helperText:
			'Array of integers (e.g., 3, 4, 5) - Number of clusters to try',
	},
	samplesList: {
		label: 'Samples List',
		helperText:
			'Array of integers (e.g., 3, 4, 5) - Number of samples to consider',
	},
	clusterEpsilons: {
		label: 'Cluster Epsilons',
		helperText: 'Array of numbers (e.g., 0.0, 0.1) - Distance threshold',
	},
	clusterMethods: {
		label: 'Cluster Methods',
		helperText:
			'Array of strings (e.g., leaf, eom) - Methods for cluster extraction',
	},
	umapNComponents: {
		label: 'UMAP Components',
		helperText:
			'Array of integers (e.g., 30, 50) - Dimensionality of embedding',
	},
	umapNEpochs: {
		label: 'UMAP Epochs',
		helperText:
			'Array of integers (e.g., 100, 200) - Number of training epochs',
	},
};

export const validateArrayInput = (category, param, value) => {
	const parsedValues = parseArrayValue(value);

	if (param === 'clusterEpsilons') {
		const isValid = parsedValues.every((num) => {
			const doubleVal = parseFloat(num);
			return !isNaN(doubleVal) && isFinite(doubleVal);
		});
		return {
			isValid,
			message: isValid
				? ''
				: 'All values in clusterEpsilons must be valid double values',
		};
	}
	if (param === 'clusterEpsilons') {
		const parsedValues = parseArrayValue(value);
		const isValid = parsedValues.every((num) => {
			const doubleVal = parseFloat(num);
			return !isNaN(doubleVal) && isFinite(doubleVal);
		});
		return {
			isValid,
			message: isValid
				? ''
				: 'All values in clusterEpsilons must be valid double values',
		};
	}
	return { isValid: true, message: '' };
};

export const parseArrayValue = (value) => {
	if (!value || (typeof value === 'string' && !value.trim())) {
		return [];
	}

	if (Array.isArray(value) && value.length > 0) {
		return value;
	}

	const stringValue = String(value);
	return stringValue
		.split(/[\s,]+/)
		.filter((v) => v.trim())
		.map((v) => {
			const parsed = parseFloat(v);
			return isNaN(parsed) ? v.trim() : parsed;
		})
		.filter((v) => v !== '');
};

export const arrayToString = (arr) => {
	return Array.isArray(arr) ? arr.join(', ') : '';
};

export const useModelTraining = (
	initialParams = PARAMETER_PRESETS.DEFAULT.params
) => {
	const [isTraining, setIsTraining] = useState(false);
	const [modelParams, setModelParams] = useState(
		JSON.parse(JSON.stringify(initialParams))
	);
	const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);

	useEffect(() => {
		const isDefault =
			JSON.stringify(modelParams) ===
			JSON.stringify(PARAMETER_PRESETS.DEFAULT.params);
		setHasUnsavedChanges(!isDefault);
	}, [modelParams]);

	const handleParamChange = (category, param, value) => {
		if (category) {
			setModelParams((prev) => ({
				...prev,
				[category]: {
					...prev[category],
					[param]: value,
				},
			}));
		} else {
			setModelParams((prev) => ({
				...prev,
				[param]: value,
			}));
		}
	};

	const handleArrayParamChange = (category, param, value) => {
		if (category) {
			setModelParams((prev) => ({
				...prev,
				[category]: {
					...prev[category],
					[param]: value,
				},
			}));
		}
	};

	const resetForm = () => {
		setModelParams(
			JSON.parse(JSON.stringify(PARAMETER_PRESETS.DEFAULT.params))
		);
	};

	return {
		isTraining,
		setIsTraining,
		modelParams,
		setModelParams,
		hasUnsavedChanges,
		handleParamChange,
		handleArrayParamChange,
		resetForm,
	};
};

export const getQuickDateRange = (preset) => {
	const end = new Date();
	const start = new Date();
	start.setDate(start.getDate() + preset.start);
	return { start, end };
};

export const processModelParams = (rawParams) => {
	return {
		...rawParams,
		hdbscanHyperparams: {
			clusterSizes: parseArrayValue(
				rawParams.hdbscanHyperparams.clusterSizes
			),
			samplesList: parseArrayValue(
				rawParams.hdbscanHyperparams.samplesList
			),
			clusterEpsilons: parseArrayValue(
				rawParams.hdbscanHyperparams.clusterEpsilons
			)
				.map((val) => {
					const doubleVal = parseFloat(val);
					return isNaN(doubleVal) ? 0.0 : doubleVal;
				})
				.filter((val) => isFinite(val)),
			clusterMethods: parseArrayValue(
				rawParams.hdbscanHyperparams.clusterMethods
			),
		},
		umapHyperparams: {
			umapNComponents: parseArrayValue(
				rawParams.umapHyperparams.umapNComponents
			),
			umapNEpochs: parseArrayValue(rawParams.umapHyperparams.umapNEpochs),
		},
		granularity: rawParams.granularity,
		minWordsPerTurn: rawParams.minWordsPerTurn,
		embeddingDimension: rawParams.embeddingDimension,
		embeddingModel: rawParams.embeddingModel,
	};
};

export const validateTrainingParams = (startTime, endTime) => {
	if (!startTime || !endTime) {
		return {
			isValid: false,
			message: 'Start time and end time are required.',
		};
	}

	if (startTime >= endTime) {
		return {
			isValid: false,
			message: 'Start time must be less than end time.',
		};
	}

	return {
		isValid: true,
		message: '',
	};
};

const ValidationSchemas = {
	arrayOfIntegers: (value, fieldName) => {
		if (
			!Array.isArray(value) ||
			!value.every((num) => Number.isInteger(num))
		) {
			throw new ValidationError(
				`${fieldName} must be an array of integers`
			);
		}
	},
	arrayOfDoubles: (value, fieldName) => {
		if (
			!Array.isArray(value) ||
			!value.every(
				(num) =>
					typeof num === 'number' &&
					!isNaN(num) &&
					num !== Infinity &&
					num !== -Infinity
			)
		) {
			throw new ValidationError(
				`${fieldName} must be an array of valid double values`
			);
		}
	},
	arrayOfNumbers: (value, fieldName) => {
		if (
			!Array.isArray(value) ||
			!value.every((num) => typeof num === 'number')
		) {
			throw new ValidationError(
				`${fieldName} must be an array of numbers`
			);
		}
	},
	arrayOfStrings: (value, fieldName) => {
		if (
			!Array.isArray(value) ||
			!value.every((str) => typeof str === 'string')
		) {
			throw new ValidationError(
				`${fieldName} must be an array of strings`
			);
		}
	},
	string: (value, fieldName) => {
		if (typeof value !== 'string') {
			throw new ValidationError(`${fieldName} must be a string`);
		}
	},
	integer: (value, fieldName) => {
		if (!Number.isInteger(value)) {
			throw new ValidationError(`${fieldName} must be an integer`);
		}
	},
};

class ValidationError extends Error {
	constructor(message) {
		super(message);
		this.name = 'ValidationError';
	}
}

export const validateModelParams = (params) => {
	try {
		const {
			hdbscanHyperparams,
			umapHyperparams,
			granularity,
			minWordsPerTurn,
			embeddingDimension,
			embeddingModel,
		} = params;

		if (!hdbscanHyperparams || !umapHyperparams) {
			throw new ValidationError('Missing required hyperparameters');
		}

		const { clusterSizes, samplesList, clusterEpsilons, clusterMethods } =
			hdbscanHyperparams;

		ValidationSchemas.arrayOfIntegers(clusterSizes, 'clusterSizes');
		if (clusterSizes.length === 0) {
			throw new ValidationError('clusterSizes cannot be an empty array');
		}

		ValidationSchemas.arrayOfIntegers(samplesList, 'samplesList');
		if (samplesList.length === 0) {
			throw new ValidationError('samplesList cannot be an empty array');
		}

		ValidationSchemas.arrayOfNumbers(clusterEpsilons, 'clusterEpsilons');
		if (clusterEpsilons.length === 0) {
			throw new ValidationError(
				'clusterEpsilons cannot be an empty array'
			);
		}

		ValidationSchemas.arrayOfStrings(clusterMethods, 'clusterMethods');
		if (clusterMethods.length === 0) {
			throw new ValidationError(
				'clusterMethods cannot be an empty array'
			);
		}

		const { umapNComponents, umapNEpochs } = umapHyperparams;

		// umapNComponents cannot be empty, but umapNEpochs can be
		ValidationSchemas.arrayOfIntegers(umapNComponents, 'umapNComponents');
		if (umapNComponents.length === 0) {
			throw new ValidationError(
				'umapNComponents cannot be an empty array'
			);
		}

		if (umapNEpochs !== undefined) {
			ValidationSchemas.arrayOfIntegers(umapNEpochs, 'umapNEpochs');
		}
		if (minWordsPerTurn === undefined) {
			throw new ValidationError('minWordsPerTurn cannot be undefined');
		}
		ValidationSchemas.integer(minWordsPerTurn, 'minWordsPerTurn');

		if (embeddingDimension === undefined) {
			throw new ValidationError('embeddingDimension cannot be undefined');
		}
		ValidationSchemas.integer(embeddingDimension, 'embeddingDimension');
		if (granularity) ValidationSchemas.string(granularity, 'granularity');
		if (embeddingModel)
			ValidationSchemas.string(embeddingModel, 'embeddingModel');

		return true;
	} catch (error) {
		if (error instanceof ValidationError) {
			throw error;
		}
		throw new ValidationError('Invalid model parameters');
	}
};