import {
	Paper,
	Divider,
	List,
	ListItem,
	ListItemIcon,
	Box,
} from '@mui/material';
import CustomerCallStatusesDist from './CustomerCallStatusesDist';
import PieChartIcon from '@mui/icons-material/PieChart';
import React, { useState, useEffect } from 'react';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import { getConfig } from './config';

const envMarker = getConfig().envMarker;

export default function IntegrationTools(props) {
	const debugPages = [
		{
			key: 'cust_call_statuses_dist',
			component: <CustomerCallStatusesDist />,
			icon: <PieChartIcon />,
			label: 'Call Status Distribution'
		}
	];

	const [selectedDebugPage, setSelectedDebugPage] = useState('cust_call_statuses_dist');

	useEffect(() => {
		const hash =
			window.location.hash.length > 0
				? window.location.hash.substr(1)
				: null;
		if (!hash) return;
		debugPages.forEach((page) => {
			if (hash.startsWith(page.key)) setSelectedDebugPage(page.key);
		});
	}, []);

	return (
		<Paper style={{ padding: '10px' }}>
			<Box display="flex" flexDirection="row">
				<Box flex="0 0 0">
					<List>
						<ListItem button component="a" href="../">
							<ListItemIcon title="Back">
								<KeyboardArrowLeftIcon />
							</ListItemIcon>
						</ListItem>
					</List>
					<Divider />
					<List>
						{debugPages.map((page) => (
							<ListItem
								button
								onClick={(e) => setSelectedDebugPage(page.key)}
								key={page.key}
								selected={page.key === selectedDebugPage}
							>
								<ListItemIcon title={page.label}>
									{page.icon}
								</ListItemIcon>
							</ListItem>
						))}
					</List>
				</Box>
				<Box
					flex="1 0 auto"
					style={{
						padding: '15px',
						height: '700px',
						overflowY: 'scroll',
					}}
				>
					{
						debugPages.filter(
							(page) => page.key === selectedDebugPage
						)[0].component
					}
				</Box>
			</Box>
		</Paper>
	);
}
