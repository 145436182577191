import { Button } from '@mui/material';
import { formElement } from 'components/common/FormControls';
import { customStyles } from 'components/customerSupport/helper';
import ConfirmCustomerDataDeletionDialog from 'data_deletion/ConfirmCustomerDataDeletionDialog';
import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { deleteCustomerData, getAllCustomers } from 'utils/network';

const CustomerDataDeletionTool = ({ disabled }) => {

  const [selectedCustomer, setSelectedCustomer] = useState();
  const [customers, setCustomers] = useState([]);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);

  useEffect(() => {
    const fetchCustomers = async () => {
      const allCustomerOptions = await getAllCustomers();
      setCustomers(allCustomerOptions);
    };
    fetchCustomers();
  }, []);

  const triggerCustomerDataDeletion = async (customerId) => {
    setShowConfirmDialog(false);
    const response = await deleteCustomerData(customerId);
    if (response?.ok) {
      alert('Customer data deletion initiated successfully. This may take upto 3 hours');
    } else {
      alert('Customer data deletion failed');
    }
  };

  return <div
    style={{
      display: 'flex',
      flexDirection: 'column',
      overflow: 'auto',
      marginBottom: '5rem',
      minHeight: '50vh',
    }}
  >
    {formElement(
      <Select
        options={customers}
        value={selectedCustomer}
        onChange={(opt) => setSelectedCustomer(opt)}
        isSearchable
        placeholder="Select Customer*"
        styles={customStyles}
      />,
    )}
    <Button
      variant="contained"
      style={{ width: '15rem', margin: '1.5rem 0' }}
      onClick={() => setShowConfirmDialog(true)}
      disabled={disabled || !selectedCustomer?._id}
    >
      Delete Customer Data
    </Button>,

    {!disabled && showConfirmDialog &&
      <ConfirmCustomerDataDeletionDialog open={showConfirmDialog} handleClose={() => setShowConfirmDialog(false)}
                                         onConfirm={() => triggerCustomerDataDeletion(selectedCustomer._id)} />}

  </div>;
};

export default CustomerDataDeletionTool;