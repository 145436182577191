import React, { useState } from 'react';
import { Button } from '@mui/material';
import Select from 'react-select';
import BulkUploadModal from 'components/BulkUploadModal';
import { customStyles } from 'components/customerSupport/helper';

const CSV_HEADERS = {
  USER_EMAIL: 'User Email',
  SECONDARY_EMAILS: 'Secondary Emails',
  NEW_SECONDARY_EMAIL: 'New Secondary Email (it will replace exiting secondary emails; please add accordingly)',
};

function UpdateBulkUsers({
  customer, allUsers, updateSecondaryEmails, clearForm,
}) {
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [openBulkUploadModal, setOpenBulkUploadModal] = useState(false);

  const handleClearForm = () => {
    setSelectedUsers([]);
    setOpenBulkUploadModal(false);
    clearForm();
  };

  const downloadCSV = () => {
    const headers = [CSV_HEADERS.USER_EMAIL, CSV_HEADERS.SECONDARY_EMAILS, CSV_HEADERS.NEW_SECONDARY_EMAIL];
    const rows = selectedUsers.map((user) => [
      user.email_id,
      user?.secondary_email_id?.join('; ') ?? '',
      '',
    ]);

    let csvContent = 'data:text/csv;charset=utf-8,';
    csvContent += `${headers.join(',')}\n`;
    rows.forEach((row) => {
      csvContent += `${row.join(',')}\n`;
    });

    const encodedUri = encodeURI(csvContent);
    const link = document.createElement('a');
    link.setAttribute('href', encodedUri);
    link.setAttribute('download', 'bulk_update_secondary_emails.csv');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleFileUpload = async (formData) => {
    const file = formData.get('csvfile');
    const reader = new FileReader();
    reader.onload = async (e) => {
      const text = e.target.result;
      const rows = text.split('\n').slice(1); // Skip header row
      const userIdsToSecondaryEmailsMap = {};

      rows.forEach((row) => {
        const csvRow = row.split(',');
        if (csvRow?.length !== 3) return;
        const [userEmail, _, newSecondaryEmailsString] = csvRow;

        const newSecondaryEmails = newSecondaryEmailsString.split(';').map((email) => email.trim()).filter((email) => !!email);
        const user = allUsers.find((u) => u.email_id === userEmail);
        if (user?._id) {
          userIdsToSecondaryEmailsMap[user._id] = newSecondaryEmails;
        }
      });
      setSelectedUsers([]);
      handleClearForm();
      await updateSecondaryEmails(userIdsToSecondaryEmailsMap);
    };
    reader.readAsText(file);
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <div style={{ display: 'flex', flexDirection: 'column', marginTop: '1rem' }}>
        Select users to be updated (by email)
        <Select
          options={allUsers}
          value={selectedUsers}
          onChange={setSelectedUsers}
          isMulti
          placeholder="Select user"
          styles={customStyles}
          isSearchable
          closeMenuOnSelect={false}
        />
      </div>
      <div style={{ display: 'flex', flexDirection: 'column', width: '33%' }}>
        <Button variant="contained" onClick={() => setOpenBulkUploadModal(true)} style={{ marginTop: '20px' }}>
          Upload CSV
        </Button>
      </div>
      <BulkUploadModal
        openModal={openBulkUploadModal}
        setOpenModal={setOpenBulkUploadModal}
        customerId={customer?._id}
        bulkUploadCsv={handleFileUpload}
        columns={[
          { headerName: CSV_HEADERS.USER_EMAIL, field: 'user_email', flex: 3 },
          { headerName: CSV_HEADERS.SECONDARY_EMAILS, field: 'secondary_emails', flex: 3 },
          { headerName: CSV_HEADERS.NEW_SECONDARY_EMAIL, field: 'new_secondary_email', flex: 3 },
        ]}
        defaultExcelText="Download CSV for selected users"
        isSampleLink={false}
        handleCsvSample={downloadCSV}
      >
        <div>
          <span>Upload Edited CSV</span>
        </div>
      </BulkUploadModal>
    </div>
  );
}

export default UpdateBulkUsers;
