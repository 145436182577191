import { getConfig } from './config';
import { Logout } from './Login';

const config = getConfig();
const backendUrl = config.backendUrl;

export function getData(url, params, signal) {
	// Include the signal in the options passed to fetch
	const options = {
		method: 'GET',
		signal: signal,
	};
	// Construct the full URL with query parameters if they exist
	const fullUrl =
		url + (params ? `?${new URLSearchParams(params).toString()}` : '');
	return fetchWithCreds(fullUrl, options);
}

export function getJsonData(url) {
	return getData(url).then((res) => res.json());
}

export async function fetchData(url, params = {}, headers = {}, signal) {
	try {
		const fullUrl =
			url + (params ? `?${new URLSearchParams(params).toString()}` : '');
		const response = await fetchWithCreds(fullUrl, {
			method: 'GET',
			headers: {
				...headers,
				'Content-Type': 'application/json',
			},
			signal: signal,
		});
		if (!response.ok) {
			throw new Error(`HTTP error! Status: ${response.status}`);
		}
		return await response.json();
	} catch (error) {
		console.error('Error fetching data:', error);
		throw error;
	}
}

export function postJson(
	url,
	data,
	extraHeaders = { 'Content-Type': 'application/json' }
) {
	return postData(url, JSON.stringify(data), extraHeaders);
}

export function postWithoutParsingBody(url, data) {
	return postData(url, data);
}

export function postData(url, body, headers) {
	return fetchWithCreds(url, {
		method: 'POST',
		headers,
		body,
	});
}

export function fetchWithCreds(url, opts = {}, logoutOnUnauthenticated = true) {
	const fetchOptions = { ...opts, credentials: 'include' };
	return fetch(backendUrl + url, fetchOptions).then((res) => {
		if (logoutOnUnauthenticated && res.status === 401) {
			alert('Unauthorized, please login again');
			Logout();
		}
		return res;
	});
}
