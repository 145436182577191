import React, { useState } from 'react';
import {
	Input,
	Button,
	Paper,
	Typography,
	Snackbar,
	Alert,
} from '@mui/material';
import { styled } from '@mui/system';
import { postJson } from './network';

const StyledPaper = styled(Paper)(({ theme }) => ({
	padding: theme.spacing(4),
	margin: '60px auto',
	maxWidth: 600,
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	justifyContent: 'center',
}));

const StyledInput = styled(Input)(({ theme }) => ({
	margin: theme.spacing(2, 0),
	width: '100%',
}));

const VideoPostProcessor = () => {
	const [callId, setCallId] = useState('');
	const [snack, setSnack] = useState({
		open: false,
		message: '',
		severity: 'info',
	});

	const handleSubmit = async () => {
		if (!callId) {
			setSnack({
				open: true,
				message: 'Please enter a Call ID.',
				severity: 'warning',
			});
			return;
		}
		try {
			const response = await postJson(
				'/support_tools/video_post_processing',
				{
					callId,
				}
			);
			const data = await response.json();
			if (response.ok) {
				setSnack({
					open: true,
					message: 'Message sent successfully: ' + data.message,
					severity: 'success',
				});
			}
			else {
				setSnack({
					open: true,
					message: "Error: " + (data.error || 'An unexpected error occurred'),
					severity: 'error',
				});
			}
		} catch (error) {
			setSnack({
				open: true,
				message: 'Error: ' + error.message,
				severity: 'error',
			});
		}
	};

	const handleClose = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}
		setSnack({ ...snack, open: false });
	};

	return (
		<StyledPaper>
			<Typography variant="h6" gutterBottom>
				Recorder Video Post Processor
			</Typography>
			<StyledInput
				placeholder="Enter Call ID"
				disableUnderline={false}
				value={callId}
				onChange={(e) => setCallId(e.target.value)}
				fullWidth
			/>
			<Button
				variant="contained"
				color="primary"
				onClick={handleSubmit}
				sx={{ marginTop: 2 }}
			>
				Send Message
			</Button>
			<Snackbar
				open={snack.open}
				autoHideDuration={6000}
				onClose={handleClose}
			>
				<Alert
					onClose={handleClose}
					severity={snack.severity}
					sx={{ width: '100%' }}
				>
					{snack.message}
				</Alert>
			</Snackbar>
		</StyledPaper>
	);
};

export default VideoPostProcessor;
