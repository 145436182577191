import React, { useState } from 'react';
import {
    TextField,
    Button,
    FormGroup,
    Backdrop,
    CircularProgress,
    Box,
    Radio,
    RadioGroup,
    FormControlLabel,
    FormLabel,
    FormControl,
    Typography,
} from '@mui/material';
import ApiIcon from '@mui/icons-material/Api';
import { postJson } from './network';
import CallIdForm from './CallIdForm';

export default function CosineMatch() {
    const [topicDescription, setTopicDescription] = useState('');
    const [callTurnTranscript, setCallTurnTranscript] = useState('');
    const [loading, setLoading] = useState(false);
    const [cosineMatchResult, setCosineMatchResult] = useState(null);
    const [error, setError] = useState(null);
    const [matchType, setMatchType] = useState('strings'); // State to track selected match type

    // State variables for storing last submitted inputs for cosine match with strings
    const [lastTopicDescription, setLastTopicDescription] = useState('');
    const [lastCallTurnTranscript, setLastCallTurnTranscript] = useState('');

    const handleOnclickForSimilarity = async () => {
        if (!topicDescription || !callTurnTranscript) {
            setError("Both Topic Description and Call Turn Transcript cannot be empty");
            return;
        }
        setLoading(true);
        setError(null);
        try {
            const response = await postJson('/similarity/cosine-match', {
                topicDescription,
                callTurnTranscript
            });

            if (!response.ok) {
                throw new Error(response.error || 'Unknown error occurred');
            }
            const resultData = await response.json();
            setCosineMatchResult(resultData.cosineMatch);
            setLastTopicDescription(topicDescription);
            setLastCallTurnTranscript(callTurnTranscript);
        } catch (e) {
            setError('Error fetching cosine match results: ' + e.message);
        }
        setLoading(false);
    };    

    return (
        <div style={{ paddingRight: '20px', width: '100%' }}>
            <Backdrop open={loading} style={{ zIndex: '5000' }}>
                <CircularProgress />
            </Backdrop>

            <h2>Cosine Match using Embeddings</h2>

            {/* Display error messages */}
            {error && (
                <Typography variant="body1" color="error" style={{ marginBottom: '20px' }}>
                    {error}
                </Typography>
            )}

            {/* Radio buttons to choose match type */}
            <FormControl component="fieldset">
                <FormLabel component="legend">Select Match Type</FormLabel>
                <RadioGroup
                    row
                    value={matchType}
                    onChange={(e) => setMatchType(e.target.value)}
                >
                    <FormControlLabel value="strings" control={<Radio />} label="Cosine Match with Strings" />
                    <FormControlLabel value="callid" control={<Radio />} label="Cosine Match with Call ID" />
                </RadioGroup>
            </FormControl>

            {matchType === 'strings' && (
                <FormGroup>
                    <Box display="flex" gap="20px" justifyContent="space-between">
                        {/* Text box for Custom Topic Description */}
                        <Box flex={1} border="1px solid #ccc" padding="10px" borderRadius="5px">
                            <TextField
                                fullWidth
                                value={topicDescription}
                                onChange={(e) => setTopicDescription(e.target.value)}
                                label="Custom Topic Description (String 1)"
                                multiline
                                rows={10}
                                inputProps={{ maxLength: 5000 }}
                                variant="outlined"
                            />
                        </Box>

                        {/* Text box for Call Turn Transcript */}
                        <Box flex={1} border="1px solid #ccc" padding="10px" borderRadius="5px">
                            <TextField
                                fullWidth
                                value={callTurnTranscript}
                                onChange={(e) => setCallTurnTranscript(e.target.value)}
                                label="Call Turn Transcript (String 2)"
                                multiline
                                rows={10}
                                inputProps={{ maxLength: 5000 }}
                                variant="outlined"
                            />
                        </Box>
                    </Box>

                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            marginTop: '20px'
                        }}
                    >
                        <Button
                            style={{ width: '100%', marginBottom: '0.7rem' }}
                            variant="contained"
                            color="primary"
                            onClick={handleOnclickForSimilarity}
                            startIcon={<ApiIcon />}
                        >
                            Calculate Embeddings Cosine Match
                        </Button>
                    </div>

                    {/* Display the last submitted input strings and the cosine match result */}
                    {cosineMatchResult && (
                        <div>
                            <h3>Input Strings:</h3>
                            <p>
                                <strong>Custom Topic Description (String 1):</strong> 
                                {lastTopicDescription.length > 100
                                    ? `${lastTopicDescription.slice(0, 100)}...`
                                    : lastTopicDescription}
                            </p>
                            <p>
                                <strong>Call Turn Transcript (String 2):</strong> 
                                {lastCallTurnTranscript.length > 100
                                    ? `${lastCallTurnTranscript.slice(0, 100)}...`
                                    : lastCallTurnTranscript}
                            </p>

                            <h3>Cosine Match Result:</h3>
                            <p>{typeof cosineMatchResult === 'object' ? JSON.stringify(cosineMatchResult) : cosineMatchResult}</p>
                        </div>
                    )}
                </FormGroup>
            )}

            {matchType === 'callid' && (
                <CallIdForm />
            )}
        </div>
    );
}
