import React, { useState } from 'react';
import {
    Input,
    Button,
    Paper,
    Typography,
    Snackbar,
    Alert,
    Box,
} from '@mui/material';
import { styled } from '@mui/system';
import { postJson } from 'network';

const StyledPaper = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(4),
    margin: '60px auto',
    maxWidth: 600,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
}));

const StyledInput = styled(Input)(({ theme }) => ({
    margin: theme.spacing(2, 0),
    width: '100%',
}));

const StatusDisplay = styled(Box)(({ theme, status }) => ({
    marginTop: theme.spacing(4),
    padding: theme.spacing(2),
    borderRadius: theme.shape.borderRadius,
    backgroundColor: status === 'INPROGRESS' ? theme.palette.success.light :
                    status === 'COMPLETED' ? theme.palette.warning.light :
                    theme.palette.error.light,
    color: theme.palette.getContrastText(
        status === 'INPROGRESS' ? theme.palette.success.light :
        status === 'COMPLETED' ? theme.palette.warning.light :
        theme.palette.error.light
    ),
    textAlign: 'center',
    width: '100%',
}));

const accessLocalStorage =()=>{
 return localStorage.getItem("email_id");
};

const CallStatusController = () => {
    const [callId, setCallId] = useState('');
    const [callStatus, setCallStatus] = useState('');
    const [snack, setSnack] = useState({
        open: false,
        message: '',
        severity: 'info',
    });

    const handleGetStatus = async () => {
        if (!callId) {
            setSnack({
                open: true,
                message: 'Please enter a Call ID.',
                severity: 'warning',
            });
            return;
        }
        try {
            const response = await postJson('/support_tools/get-call-status', {
                callId,
            });
            const data = await response.json();
            setCallStatus(data.callStatus || 'unknown');
            setSnack({
                open: true,
                message: response.ok ? data.message : 'Error fetching status',
                severity: response.ok ? 'success' : 'error',
            });
        } catch (error) {
            setSnack({
                open: true,
                message: 'Error: ' + error.message,
                severity: 'error',
            });
        }
    };

    const handleReportStatus = async () => {
        const email_id = accessLocalStorage();
        if (!callId) {
            setSnack({
                open: true,
                message: 'Please enter a Call ID.',
                severity: 'warning',
            });
            return;
        }
        try {
            const response = await postJson('/support_tools/set-call-completed', {
                callId,
                email_id
            });
            const data = await response.json();
            setSnack({
                open: true,
                message: response.ok ? data.message : data.error,
                severity: response.ok ? 'success' : 'error',
            });
        } catch (error) {
            setSnack({
                open: true,
                message: 'Error: ' + error.message,
                severity: 'error',
            });
        }
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnack({ ...snack, open: false });
    };

    return (
        <StyledPaper>
            <Typography variant="h6" gutterBottom>
                Call Status Controller
            </Typography>
            <StyledInput
                placeholder="Enter Call ID"
                disableUnderline={false}
                value={callId}
                onChange={(e) => setCallId(e.target.value)}
                fullWidth
            />
            <Button
                variant="contained"
                color="primary"
                onClick={handleGetStatus}
                sx={{ marginTop: 2 }}
            >
                Get Call Status
            </Button>
            <Button
            variant="contained"
            color="primary"
            onClick={handleReportStatus}
            sx={{ marginTop: 2 }}>
                Stop Call
            </Button>
            <Button
                variant="contained"
                color="secondary"
                onClick={() => setCallStatus('')}
                sx={{ marginTop: 2 }}
            >
                Clear Status
            </Button>
            {callStatus && (
                <StatusDisplay status={callStatus}>
                    <Typography variant="h6">
                        {`Call Status: ${callStatus}`}
                    </Typography>
                </StatusDisplay>
            )}
            <Snackbar
                open={snack.open}
                autoHideDuration={6000}
                onClose={handleClose}
            >
                <Alert
                    onClose={handleClose}
                    severity={snack.severity}
                    sx={{ width: '100%' }}
                >
                    {snack.message}
                </Alert>
            </Snackbar>
        </StyledPaper>
    );
};

export default CallStatusController;
