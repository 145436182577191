import React, { useState } from 'react';
import {
    TextField,
    Button,
    FormGroup,
    FormControl,
    Box,
    Typography,
} from '@mui/material';
import ApiIcon from '@mui/icons-material/Api';
import PropTypes from 'prop-types';
import { postJson } from './network';

export default function CallIdForm({
    customTopicDescription,
    setCustomTopicDescription,
    callId,
    setCallId,
}) {
    const [loading, setLoading] = useState(false);
    const [results, setResults] = useState([]); // Results to display
    const [error, setError] = useState(null);
    const [localCustomTopicDescription, setLocalCustomTopicDescription] = useState(customTopicDescription); // Local state for input
    const [localCallId, setLocalCallId] = useState(callId); // Local state for input
    const [submittedCustomTopicDescription, setSubmittedCustomTopicDescription] = useState(''); // State for submitted description

    // Function to handle API call for cosine match with Call ID
    const handleCallIdMatch = async () => {
        if (!localCustomTopicDescription || !localCallId) {
            setError('Both Custom Topic Description and Call ID cannot be empty');
            return;
        }
        setLoading(true);
        setError(null);
        try {
            const response = await postJson('/similarity/cosine-match-callId', {
                customTopicDescription: localCustomTopicDescription,
                callId: localCallId,
            });
    
            // Handle non-OK response (status codes outside 200-299)
            if (!response.ok) {
                throw new Error(response.error || 'Unknown error occurred'); // Use the error field from the response directly
            }

            const resultData = await response.json();
            setResults(resultData.results); 
            setSubmittedCustomTopicDescription(localCustomTopicDescription); // Update the submitted description
    
        } catch (e) {
            setError('Error fetching cosine match results: ' + e.message);
        } finally {
            setLoading(false);
        }
    };
    
    return (
        <FormGroup>
            {/* Custom Topic Description (String 1) */}
            <FormControl
                variant="outlined"
                style={{ marginBottom: '1rem' }}
                fullWidth
            >
                <TextField
                    fullWidth
                    value={localCustomTopicDescription}
                    onChange={(e) => setLocalCustomTopicDescription(e.target.value)} // Update local state
                    label="Custom Topic Description (String 1)"
                    variant="outlined"
                    size="small" // Match the size and variant as in DebugCallSummaries.jsx
                />
            </FormControl>

            {/* Call ID */}
            <FormControl
                variant="outlined"
                style={{ marginBottom: '1rem' }}
                fullWidth
            >
                <TextField
                    fullWidth
                    value={localCallId}
                    onChange={(e) => setLocalCallId(e.target.value)} // Update local state
                    label="Call ID"
                    variant="outlined"
                    size="small" // Match the size and variant as in DebugCallSummaries.jsx
                />
            </FormControl>

            {/* Button to trigger the Cosine Match */}
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    marginTop: '20px',
                }}
            >
                <Button
                    style={{ width: '100%', marginBottom: '0.7rem' }}
                    variant="contained"
                    color="primary"
                    onClick={handleCallIdMatch} // Update to call the new function
                    startIcon={<ApiIcon />}
                >
                    Calculate Embeddings Cosine Match
                </Button>
            </div>

            {/* Loading state */}
            {loading && <Typography variant="body1">Loading...</Typography>}

            {/* Error message */}
            {error && <Typography variant="body1" color="error">{error}</Typography>}

            {/* Results Display */}
            {results?.length > 0 && (
                <Box marginTop="20px">
                    <Typography variant="h6">
                        Cosine Match Results between String 1 ("{submittedCustomTopicDescription.length > 50
                            ? `${submittedCustomTopicDescription.substring(0, 50)}...`
                            : submittedCustomTopicDescription}") and Call Turn Transcript:
                    </Typography>
                    {results.map((result, index) => (
                        <Box
                            key={index}
                            padding="10px"
                            border="1px solid #ccc"
                            marginTop="10px"
                            borderRadius="5px"
                        >
                            <Typography variant="body2">
                                <strong>Transcription:</strong> {result.transcription.length > 100
                                    ? `${result.transcription.substring(0, 100)}...`
                                    : result.transcription}
                            </Typography>
                            <Typography variant="body2">
                                <strong>Similarity:</strong> {result.similarity.toFixed(4)}
                            </Typography>
                        </Box>
                    ))}
                </Box>
            )}
        </FormGroup>
    );
}

CallIdForm.propTypes = {
    customTopicDescription: PropTypes.string.isRequired,
    setCustomTopicDescription: PropTypes.func.isRequired,
    callId: PropTypes.string.isRequired,
    setCallId: PropTypes.func.isRequired,
};