import { Tab, Tabs } from '@mui/material';
import CallDeletionTool from 'data_deletion/CallDeletionTool';
import CustomerDataDeletionTool from 'data_deletion/CustomerDataDeletionTool';
import { useState } from 'react';

const TABS = {
  CUSTOMER_DATA_DELETION: 0,
  CALL_DATA_DELETION: 1,
};

const DataDeletionTool = ({ disabled }) => {
  const [activeTab, setActiveTab] = useState(TABS.CUSTOMER_DATA_DELETION);

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem', overflowY: 'auto', height: '100%' }}>
      <Tabs value={activeTab} onChange={handleTabChange}>
        <Tab label="Customer Data Cleanup" />
        <Tab label="Call Data Deletion" />
      </Tabs>
      {activeTab === TABS.CUSTOMER_DATA_DELETION && <CustomerDataDeletionTool disabled={disabled} />}
      {activeTab === TABS.CALL_DATA_DELETION && <CallDeletionTool disabled={disabled} />}
    </div>
  );
};

export default DataDeletionTool;