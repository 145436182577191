import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import ConfirmCallDeletionDialog from 'data_deletion/ConfirmCallDeletionDialog';
import { DeleteIcon, ExcelSheetIcon } from 'designSystem';
import React, { useEffect, useState } from 'react';

import { CALL_DELETION_REQUEST_STATUS, CONTENT_TYPE } from 'utils/constants';
import { downloadFileFromS3 } from 'utils/network';

const REQUEST_TABLE_COLUMN_NAMES = {
  DATE: 'date',
  CLARI_AM: 'clari_am',
  CUSTOMER_POC: 'customer_poc',
  STATUS: 'status',
  DELETE: 'delete',
  DOWNLOAD_CALLS: 'download_calls',
  DOWNLOAD_GAMETAPES: 'download_gametapes',
  DOWNLOAD_SNIPPETS: 'download_snippets',
  DELETED_CALLS: 'deleted_calls',
  DELETED_GAMETAPES: 'delted_gametapes',
  DLEETED_SNIPPETS: 'deleted_snippets',
  DELETION_DATE_THRESHOLD: 'deletion_date',
  PARTICIPANT_PHONE_NUMBERS: 'participant_phones',
  PARICIPANT_EMAILS: 'participant_emails',
  SELECTED_MANAGERS: 'selected_managers',
};

const customStyles = {
  cell_scroll: {
    height: '100%',
    overflow: 'scroll',
    padding: '1.5px',
    border: '1px outset',
  },
};

function DeletionRequestsTable({
                                 allDeletionRequests,
                                 updateAllDeletionRequestsForCustomer,
                                 allManagers,
                                 disabled,
                               }) {
  const [showConfirmDeletionModal, setShowConfirmDeletionModal] = useState(false);
  const [currentRequest, setCurrentRequest] = useState(null);

  const handleCallDeleteModal = (req) => {
    if (!isDeletionPending(req?.status)) return;
    setCurrentRequest(req);
    setShowConfirmDeletionModal(true);
  };

  const closeConfirmDeletionDialog = () => {
    setShowConfirmDeletionModal(false);
    updateAllDeletionRequestsForCustomer();
  };

  return (
    <Paper
      style={{
        width: '98%',
        overflow: 'hidden',
        border: '1px solid rgba(0,0,0,0.6)',
        borderColor: 'rgba(0,0,0,0.6)',
        flexShrink: 0,
      }}
    >
      <TableContainer style={{ maxHeight: 400, minHeight: 200 }}>
        <Table
          stickyHeader
          style={{ position: 'relative', tableLayout: 'fixed' }}
        >
          <TableHeader />
          <TableBody>
            {allDeletionRequests.map((req, index) => (
              <RequestRow
                req={req}
                handleCallDeleteModal={handleCallDeleteModal}
                allManagers={allManagers}
                disabled={disabled}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {!disabled && <ConfirmCallDeletionDialog
        requestDoc={currentRequest}
        open={showConfirmDeletionModal}
        handleClose={closeConfirmDeletionDialog}
      />}
    </Paper>
  );
}

const isExcelGenerated = (requestStatus, s3_url) => requestStatus
  && ![
    CALL_DELETION_REQUEST_STATUS.GENERATING,
    CALL_DELETION_REQUEST_STATUS.GENERATION_FAILED,
  ].includes(requestStatus)
  && s3_url;
const isDeletionPending = (requestStatus) => requestStatus && requestStatus === CALL_DELETION_REQUEST_STATUS.GENERATED;

function TableHeader() {
  return (
    <TableHead
      style={{
        position: 'sticky',
        top: 0,
        backgroundColor: 'white',
      }}
    >
      <TableRow>
        <TableCell width={150} key={REQUEST_TABLE_COLUMN_NAMES.DATE}>
          {'Deletion requested on'}
        </TableCell>
        <TableCell width={70} key={REQUEST_TABLE_COLUMN_NAMES.STATUS}>
          {'Status'}
        </TableCell>
        <TableCell
          width={70}
          key={REQUEST_TABLE_COLUMN_NAMES.DOWNLOAD_CALLS}
          align="center"
        >
          Calls
        </TableCell>
        <TableCell
          width={80}
          key={REQUEST_TABLE_COLUMN_NAMES.DOWNLOAD_GAMETAPES}
          align="center"
        >
          Game tapes
        </TableCell>
        <TableCell
          width={50}
          key={REQUEST_TABLE_COLUMN_NAMES.DOWNLOAD_SNIPPETS}
          align="center"
        >
          Snippets
        </TableCell>
        <TableCell
          width={65}
          key={REQUEST_TABLE_COLUMN_NAMES.DELETE}
          align="center"
        >
          Delete
        </TableCell>
        <TableCell
          width={100}
          key={REQUEST_TABLE_COLUMN_NAMES.DELETED_CALLS}
          align="center"
        >
          Deleted Calls (%)
        </TableCell>
        <TableCell
          width={100}
          key={REQUEST_TABLE_COLUMN_NAMES.DELETED_GAMETAPES}
          align="center"
        >
          Deleted Gametapes (%)
        </TableCell>
        <TableCell
          width={100}
          key={REQUEST_TABLE_COLUMN_NAMES.DLEETED_SNIPPETS}
          align="center"
        >
          Deleted Snippets (%)
        </TableCell>
        <TableCell
          width={150}
          key={REQUEST_TABLE_COLUMN_NAMES.CLARI_AM}
        >
          {'Triggered by (Clari AM / Support / CSM)'}
        </TableCell>
        <TableCell
          width={150}
          key={REQUEST_TABLE_COLUMN_NAMES.CUSTOMER_POC}
        >
          {'Requested by customer'}
        </TableCell>
        <TableCell
          width={150}
          key={REQUEST_TABLE_COLUMN_NAMES.DELETION_DATE_THRESHOLD}
        >
          {'Delete calls made before'}
        </TableCell>
        <TableCell
          width={250}
          key={REQUEST_TABLE_COLUMN_NAMES.PARTICIPANT_PHONE_NUMBERS}
        >
          {'Participant emails'}
        </TableCell>
        <TableCell
          width={250}
          key={REQUEST_TABLE_COLUMN_NAMES.PARICIPANT_EMAILS}
        >
          {'Participant phone numbers'}
        </TableCell>
        <TableCell
          width={250}
          key={REQUEST_TABLE_COLUMN_NAMES.SELECTED_MANAGERS}
        >
          {'Selected Managers'}
        </TableCell>
      </TableRow>
    </TableHead>
  );
}

function RequestRow({ req, handleCallDeleteModal, allManagers, disabled }) {
  const [deletedGametapes, setDeletedGameTapes] = useState('N/A');
  const [deletedCalls, setDeletedCalls] = useState('N/A');
  const [deletedSnippets, setDeletedSnippets] = useState('N/A');

  useEffect(() => {
    if (req?.status === CALL_DELETION_REQUEST_STATUS.DELETED) {
      setDeletedCalls('100%');
      setDeletedGameTapes('100%');
      setDeletedSnippets('100%');
      return;
    }
    if (req?.status !== CALL_DELETION_REQUEST_STATUS.DELETING) return;
    const showDeletingCallsPerct = req?.total_calls_to_delete
      && (req?.deleted_calls || req?.deleted_calls === 0);
    if (showDeletingCallsPerct) {
      setDeletedCalls(
        `${Math.floor(
          (req?.deleted_calls * 100) / req?.total_calls_to_delete,
        ).toString()}%`,
      );
    }
    const showDeletingGameTapessPerct = req?.total_game_tapes_to_delete
      && (req?.deleted_game_tapes || req?.deleted_game_tapes === 0);
    if (showDeletingGameTapessPerct) {
      setDeletedGameTapes(
        `${Math.floor(
          (req?.deleted_game_tapes * 100)
          / req?.total_game_tapes_to_delete,
        ).toString()}%`,
      );
    }
    const showDeletingSnippetsPerct = req?.total_shared_snippet_to_delete
      && (req?.deleted_shared_snippet || req?.deleted_shared_snippet === 0);
    if (showDeletingSnippetsPerct) {
      setDeletedSnippets(
        `${Math.floor(
          (req?.deleted_shared_snippet * 100)
          / req?.total_shared_snippet_to_delete,
        ).toString()}%`,
      );
    }
  }, [req]);

  function getAllManagerNamesById(mangerIds) {
    return allManagers.filter((manager) => mangerIds.includes(manager._id))
      .reduce((acc, manager) => acc + manager?.display_name + ', ', '').trim();
  }

  return (
    <TableRow hover tabIndex={-1} key={req?._id || index}>
      <TableCell>
        {/* {req?.request_date} */}
        {new Date(req?.request_date).toUTCString()}
      </TableCell>
      <TableCell>{req?.status}</TableCell>
      <TableCell align="center">
        {excelSheetDownloadIcon(
          req?.status,
          req?.call_s3_link,
          'calls.csv',
        )}
      </TableCell>
      <TableCell align="center">
        {excelSheetDownloadIcon(
          req?.status,
          req?.gametape_s3_link,
          'gametapes.csv',
        )}
      </TableCell>
      <TableCell align="center">
        {excelSheetDownloadIcon(
          req?.status,
          req?.snippet_s3_link,
          'snippets.csv',
        )}
      </TableCell>
      <TableCell align="center">
        <DeleteIcon
          style={{
            cursor: isDeletionPending(req?.status) && !disabled
              ? 'pointer'
              : 'not-allowed',
            height: 20,
            width: 20,
          }}
          onClick={() => {
            handleCallDeleteModal(req);
          }}
          stroke={
            isDeletionPending(req?.status) ? '#A7171A' : '#808080'
          }
        />
      </TableCell>
      <TableCell align="center">{deletedCalls}</TableCell>
      <TableCell align="center">{deletedGametapes}</TableCell>
      <TableCell align="center">{deletedSnippets}</TableCell>
      <TableCell style={{ wordWrap: 'break-word' }}>
        {req?.requested_by}
      </TableCell>
      <TableCell style={{ wordWrap: 'break-word' }}>
        {req?.customer_poc_email}
      </TableCell>
      <TableCell style={{ wordWrap: 'break-word' }}>
        {new Date(req?.delete_upto).toUTCString()}
      </TableCell>
      <TableCell style={{ wordWrap: 'break-word', height: '95px' }}>
        <div style={customStyles.cell_scroll}>
          {req?.external_emails?.join(', ').trim() || 'NA'}
        </div>
      </TableCell>
      <TableCell style={{ wordWrap: 'break-word', height: '95px' }}>
        <div style={customStyles.cell_scroll}>
          {req?.external_phones?.join(', ').trim() || 'NA'}
        </div>
      </TableCell>
      <TableCell style={{ wordWrap: 'break-word', height: '95px' }}>
        <div style={customStyles.cell_scroll}>
          {getAllManagerNamesById(req?.managers_ids) || 'NA'}
        </div>
      </TableCell>
    </TableRow>
  );
}

const excelSheetDownloadIcon = (requestStatus, s3_url, fileName) => {
  const disabled = !isExcelGenerated(requestStatus, s3_url);
  const downloadExcelForRequest = () => {
    if (disabled) return;
    downloadFileFromS3(s3_url, fileName, CONTENT_TYPE.CSV);
  };
  return (
    <ExcelSheetIcon
      style={{
        cursor: disabled ? 'not-allowed' : 'pointer',
        height: 20,
        width: 20,
      }}
      onClick={() => downloadExcelForRequest(s3_url, fileName)}
      fill={disabled ? '#808080' : '#20744a'}
    />
  );
};

export default DeletionRequestsTable;
