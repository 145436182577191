import React from 'react';
import { makeStyles } from '@mui/styles';
import { useState, useEffect } from 'react';
import {
	Paper,
	Divider,
	Box,
	CircularProgress,
	Typography,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Menu,
	MenuItem,
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { getJsonData } from 'network';
import { postJson } from 'network';
import CategoryIcon from '@mui/icons-material/Category';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { validateModelParams } from 'topicModelingHelpers';

export const useStyles = makeStyles((theme) => ({
	recorderExperiments: {
		display: 'flex',
		height: '100vh',
		backgroundColor: '#f9f9f9',
	},
	content: {
		flexGrow: 1,
		padding: theme.spacing(3),
		overflowY: 'auto',
	},
	listItem: {
		borderRadius: theme.shape.borderRadius,
		marginBottom: theme.spacing(1),
		'&:hover': {
			backgroundColor: theme.palette.action.hover,
		},
	},
	listItemSelected: {
		backgroundColor: theme.palette.primary.main,
		color: theme.palette.primary.contrastText,
		'&:hover': {
			backgroundColor: theme.palette.primary.dark,
		},
	},
	helpButton: {
		marginTop: 'auto',
	},
	dialogContent: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-between',
		height: '100%',
	},
	dialogBox: {
		height: '100%',
	},
	tableHeading: {
		marginBottom: theme.spacing(2),
	},
}));

export async function fetchCustomerData() {
	try {
		const endpoint = '/customers/all';
		const response = await getJsonData(endpoint);
		return response;
	} catch (error) {
		console.error('Error fetching customer data:', error);
		return [];
	}
}

export async function trainModel(
	startTime,
	endTime,
	objectType,
	topicType,
	jobVersion,
	selectedCustomerId,
	runType,
	modelParams,
) {
	try {
		console.log('Starting model training with parameters:', {
			startTime,
			endTime,
			objectType,
			topicType,
			jobVersion,
			selectedCustomerId,
			runType,
			modelParams,
		});

		validateModelParams(modelParams);
		console.log('Model parameters validated successfully.');

		const payload = {
			startTime: startTime?.toISOString() ?? null,
			endTime: endTime?.toISOString() ?? null,
			objectType,
			topicType,
			jobVersion,
			customerId: selectedCustomerId,
			runType,
			hdbscanHyperparams: modelParams.hdbscanHyperparams,
			umapHyperparams: modelParams.umapHyperparams,
			granularity: modelParams.granularity,
			minWordsPerTurn: modelParams.minWordsPerTurn,
			embeddingDimension: modelParams.embeddingDimension,
			embeddingModel: modelParams.embeddingModel,
		};

		console.log('Payload being sent to backend:', payload);

		const response = await postJson('/smart_topics/train-model', payload);
		const data = await response.json();

		if (response.ok) {
			console.log('Model training started successfully:', data);
			return {
				message: `Model training started for Job ID: ${
					data.jobId || 'Unknown'
				}`,
				severity: 'success',
			};
		} else {
			if (response.status === 400) {
				const missingFields = data.missingFields || [];
				const errorMessage = `Error: ${
					data.message || 'Training failed'
				}`;
				const missingFieldsMessage = missingFields.length
					? ` Missing fields: ${missingFields.join(', ')}.`
					: '';

				return {
					message: `${errorMessage}${missingFieldsMessage}`,
					severity: 'error',
				};
			}
			return {
				message: `Error: ${data.message || 'Training failed'}`,
				severity: 'error',
			};
		}
	} catch (error) {
		console.error('Error in trainModel:', {
			error,
			message: error.message,
			stack: error.stack,
		});

		// Log specific error types
		if (error.name === 'ValidationError') {
			console.error('Validation failed:', error.message);
		} else if (error.name === 'NetworkError') {
			console.error('Network request failed:', {
				status: error.status,
				statusText: error.statusText,
			});
		}

		return {
			message:
				error.message || 'Error: Training failed due to network issues',
			severity: 'error',
		};
	}
}

export function ModelsTab({ customerId, customerName, modelId }) {
	const classes = useStyles();
	const [models, setModels] = useState([]);
	const [loading, setLoading] = useState(false);
	const [openDialog, setOpenDialog] = useState(false);
	const [openAssignDialog, setOpenAssignDialog] = useState(false);
	const [selectedModel, setSelectedModel] = useState(null);
	const [dataDumpLoading, setDataDumpLoading] = useState(false);
	const [anchorEl, setAnchorEl] = useState(null);
	const [selectedModelForDump, setSelectedModelForDump] = useState(null);

	const handleDataDumpClick = (event, model) => {
		setAnchorEl(event.currentTarget);
		setSelectedModelForDump(model);
	};

	const handleMenuClose = () => {
		setAnchorEl(null);
		setSelectedModelForDump(null);
	};

	const handleSmartTopicDump = async () => {
		handleMenuClose();
		if (!selectedModelForDump) return;

		setDataDumpLoading(true);
		try {
			const response = await postJson(
				'/smart_topics/data-dump',
				{
					modelId: selectedModelForDump.modelId,
					customerId,
					version: selectedModelForDump.version,
					type: 'SMART_TOPIC',
				},
				{ 'Content-Type': 'application/json' }
			);

			const result = await response.json();
			if (response.ok) {
				alert(result.message);
			} else {
				alert(result.error);
			}
		} catch (error) {
			console.error('Error during smart topic data dump:', error);
			alert('Error initiating smart topic data dump. Please try again.');
		} finally {
			setDataDumpLoading(false);
		}
	};

	const handleCustomTopicDump = async () => {
		handleMenuClose();
		if (!selectedModelForDump) return;

		setDataDumpLoading(true);
		try {
			const response = await postJson(
				'/custom_topics/data-dump',
				{
					modelId: selectedModelForDump.modelId,
					customerId,
					version: selectedModelForDump.version,
					type: 'CUSTOM_TOPIC',
				},
				{ 'Content-Type': 'application/json' }
			);

			const result = await response.json();
			if (response.ok) {
				alert(result.message);
			} else {
				alert(result.error);
			}
		} catch (error) {
			console.error('Error during custom topic data dump:', error);
			alert('Error initiating custom topic data dump. Please try again.');
		} finally {
			setDataDumpLoading(false);
		}
	};

	const fetchAllModels = async () => {
		if (!customerId) return;

		setLoading(true);
		try {
			const allModelsEndpoint = `/smart_topics/models/${customerId}`;
			const allModelsData = await getJsonData(allModelsEndpoint);
			const assignedModelsEndpoint = `/smart_topics/models/${customerId}?alreadyAssigned=true`;
			const assignedModelsData = await getJsonData(
				assignedModelsEndpoint
			);
			const assignedModelIds = new Set(
				assignedModelsData.modelInfo.map((model) => model.modelId)
			);

			const combinedModels = allModelsData.modelInfo.map((model) => ({
				...model,
				assigned: assignedModelIds.has(model.modelId),
				id: model.modelId,
			}));
			if (modelId) {
				const filteredModels = combinedModels.filter(
					(model) => model.modelId === modelId
				);
				setModels(filteredModels);
			} else {
				setModels(combinedModels);
			}
		} catch (error) {
			console.error('Error fetching models:', error);
			setModels([]);
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		fetchAllModels();
	}, [customerId, modelId]);

	const handleOpenDialog = (model) => {
		setSelectedModel(model);
		setOpenDialog(true);
	};

	const handleCloseDialog = () => {
		setOpenDialog(false);
		setSelectedModel(null);
	};

	const handleOpenAssignDialog = (model) => {
		setSelectedModel(model);
		setOpenAssignDialog(true);
	};

	const handleCloseAssignDialog = () => {
		setOpenAssignDialog(false);
		setSelectedModel(null);
	};

	const handleDeleteModel = async () => {
		if (!selectedModel) return;

		try {
			const response = await getJsonData(
				`/smart_topics/delete-model-assignment/${selectedModel.modelId}`
			);
			if (response.message === 'Model Unassigned successfully') {
				fetchAllModels();
				handleCloseDialog();
			} else {
				console.error('Error deleting model:', response.error);
			}
		} catch (error) {
			console.error('Error deleting model:', error);
		}
	};

	const handleAssignModel = async () => {
		if (!selectedModel) return;

		try {
			const headers = { 'Content-Type': 'application/json' };
			const response = await postJson(
				'/smart_topics/assign-model',
				{
					modelId: selectedModel.modelId,
					customerId: customerId,
				},
				headers
			);

			if (response.ok) {
				fetchAllModels();
				handleCloseAssignDialog();
			} else {
				console.error('Error assigning model:', await response.json());
			}
		} catch (error) {
			console.error('Error assigning model:', error);
		}
	};
	const handleCustomTopicDataDumpClick = async (model) => {
		const modelId = model.modelId;
		const version = model.version;
		const type = 'CUSTOM_TOPIC';
		setDataDumpLoading(true);

		try {
			console.log('Initiating custom topic data dump with parameters:', {
				modelId,
				customerId,
				version,
				type,
			});

			const response = await postJson(
				'/custom_topics/data-dump',
				{ modelId, customerId, version, type },
				{ 'Content-Type': 'application/json' }
			);

			const result = await response.json();

			if (response.ok) {
				console.log('Custom topic data dump result:', result.message);
				alert(result.message);
			} else {
				console.error('Custom topic data dump failed:', result.error);
				alert(result.error);
			}
		} catch (error) {
			console.error('Error during custom topic data dump:', error);
			alert('Error initiating custom topic data dump. Please try again.');
		} finally {
			setDataDumpLoading(false);
		}
	};

	const columns = [
		{
			field: 'modelId',
			headerName: 'Model ID',
			width: 300,
			align: 'center',
			headerAlign: 'center',
		},
		{
			field: 'applicableFrom',
			headerName: 'Applicable From',
			width: 300,
			align: 'center',
			headerAlign: 'center',
			valueGetter: ({ value }) => new Date(value).toLocaleString(),
		},
		{
			field: 'applicableUntil',
			headerName: 'Applicable Until',
			width: 200,
			align: 'center',
			headerAlign: 'center',
			valueGetter: ({ value }) => new Date(value).toLocaleString(),
		},
		{
			field: 'modelCreatedAt',
			headerName: 'Model Created At',
			width: 200,
			align: 'center',
			headerAlign: 'center',
			valueGetter: ({ value }) => new Date(value).toLocaleString(),
		},
		{
			field: 'assigned',
			headerName: 'Status',
			width: 120,
			align: 'center',
			headerAlign: 'center',
			renderCell: (params) => (
				<Box display="flex" justifyContent="center">
					<Button
						variant="contained"
						color={params.value ? 'success' : 'error'}
						size="small"
						onClick={() =>
							params.value
								? handleOpenDialog(params.row)
								: handleOpenAssignDialog(params.row)
						}
					>
						{params.value ? 'Assigned' : 'Assign'}
					</Button>
				</Box>
			),
		},

		{
			field: 'dataDump',
			headerName: 'Data Dump',
			width: 160,
			align: 'cemter',
			headerAlign: 'center',
			renderCell: (params) => (
				<div>
					<Button
						variant="contained"
						startIcon={<CloudUploadIcon />}
						endIcon={<ArrowDropDownIcon />}
						sx={{
							fontWeight: 'bold',
							color: '#F9EFBC',
							'&:hover': {
								backgroundColor: '#1976d2',
								color: '#fff',
							},
						}}
						onClick={(event) =>
							handleDataDumpClick(event, params.row)
						}
						disabled={dataDumpLoading}
					>
						{dataDumpLoading ? (
							<CircularProgress size={24} />
						) : (
							'Data Dump'
						)}
					</Button>
					<Menu
						anchorEl={anchorEl}
						open={Boolean(anchorEl)}
						onClose={handleMenuClose}
					>
						{selectedModelForDump?.assigned && (
							<MenuItem onClick={handleSmartTopicDump}>
								Smart Topics Dump
							</MenuItem>
						)}
						<MenuItem onClick={handleCustomTopicDump}>
							Custom Topics Dump
						</MenuItem>
					</Menu>
				</div>
			),
		},
	];
	return (
		<Box>
			<Box
				display="flex"
				flexDirection="column"
				alignItems="center"
				mb={4}
			>
				<Box display="flex" alignItems="center">
					<CategoryIcon
						sx={{ fontSize: 40, color: 'primary.main', mr: 2 }}
					/>
					<Typography
						variant="h3"
						component="h1"
						color="primary"
						fontWeight="bold"
					>
						Model Information
					</Typography>
				</Box>
				<Typography
					variant="subtitle1"
					color="text.secondary"
					align="center"
					mt={3}
				>
					View and manage models for Customer:{' '}
					<Typography
						component="span"
						variant="subtitle1"
						color="secondary"
						style={{ fontWeight: 'bold' }}
					>
						{customerName}
					</Typography>
				</Typography>
				<Divider
					sx={{ width: '80%', mt: 2, borderColor: 'primary.main' }}
				/>
			</Box>

			{loading ? (
				<Box display="flex" justifyContent="center" mt={5}>
					<CircularProgress />
				</Box>
			) : (
				<Paper style={{ height: 600, width: '100%' }}>
					<DataGrid
						rows={models}
						columns={columns}
						pageSize={10}
						rowsPerPageOptions={[5, 10, 20]}
						pagination
						getRowId={(row) => row.modelId}
					/>
				</Paper>
			)}

			{/* Dialog for Confirming Deletion */}
			<Dialog open={openDialog} onClose={handleCloseDialog}>
				<DialogTitle>Confirm Deletion</DialogTitle>
				<DialogContent>
					<Typography>
						Are you sure you want to Unassign model ID{' '}
						<Typography
							component="span"
							fontWeight="bold"
							color="primary"
						>
							{selectedModel?.modelId}
						</Typography>{' '}
						for customer ID{' '}
						<Typography
							component="span"
							fontWeight="bold"
							color="primary"
						>
							{customerId}
						</Typography>
						?
					</Typography>
				</DialogContent>
				<DialogActions
					sx={{ justifyContent: 'space-between', padding: '16px' }}
				>
					<Button
						onClick={handleCloseDialog}
						variant="outlined"
						color="primary"
						sx={{ minWidth: '120px' }}
					>
						Cancel
					</Button>
					<Button
						onClick={handleDeleteModel}
						variant="contained"
						color="secondary"
						sx={{ minWidth: '120px' }}
					>
						Confirm
					</Button>
				</DialogActions>
			</Dialog>

			{/* Dialog for Confirming Assignment */}
			<Dialog open={openAssignDialog} onClose={handleCloseAssignDialog}>
				<DialogTitle>Confirm Assignment</DialogTitle>
				<DialogContent>
					<Typography>
						Are you sure you want to assign model ID{' '}
						<Typography
							component="span"
							fontWeight="bold"
							color="primary"
						>
							{selectedModel?.modelId}
						</Typography>{' '}
						to customer ID{' '}
						<Typography
							component="span"
							fontWeight="bold"
							color="primary"
						>
							{customerId}
						</Typography>
						?
					</Typography>
				</DialogContent>
				<DialogActions
					sx={{ justifyContent: 'space-between', padding: '16px' }}
				>
					<Button
						onClick={handleCloseAssignDialog}
						variant="outlined"
						color="primary"
						sx={{ minWidth: '120px' }}
					>
						Cancel
					</Button>
					<Button
						onClick={handleAssignModel}
						variant="contained"
						color="success"
						sx={{ minWidth: '120px' }}
					>
						Confirm
					</Button>
				</DialogActions>
			</Dialog>
		</Box>
	);
}
